 .audio {
    position: relative;
    top: -1px;

    &_top10 {
        & ~ div {
            top: 10px;
        }
    }
}
