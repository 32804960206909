.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #ffffff;
    
    & > .name {
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
    }

    & > .description {
        margin-top: 2px;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
    }
}
