.wrapper {
    height: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: height linear 0.2s;
    width: 100%;
    z-index: 99;

    &_open {
        height: 250px;
    }
}

.dialog {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #48587d;
    font-weight: 500;
    padding: 15px 23px 0px;
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.35);
    border-radius: 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    & > div:first-child {
        margin-bottom: 15px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
    }

    & .list {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            li {
                margin: 15px 2px;
                a {
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    & img {
                        margin-right: 15px;
                    }
                }
            }
        }
    }
}
