.wrapper {
    width: 100%;
    height: 100vh;
    position: relative;

    .background {
        height: 100%;
        width: 100%;
        background: #121213;
        opacity: 0.9;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        &_player {
            z-index: 4;
        }
    }

    .report {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;

        .header {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 12px 30px;
            min-height: 70px;
            box-sizing: border-box;
            align-items: center;

            & > img {
                cursor: pointer;
            }

            & > div {
                position: relative;
                display: block;
            }
        }

        .footer {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 3;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 58px;
            margin: 5px 0;
            padding: 0 5px;
            box-sizing: border-box;

            .button {
                background-color: #d05353;
                border-radius: 8px;
                width: 129px;
                height: 37px;
                margin-right: 10px;
                &:hover {
                    background-color: #d05353;
                }
            }
        }

        .content {
            width: 900px;
            height: 100%;
            min-height: 100vh;
            position: relative;
            z-index: 3;
            display: flex;
            color: #ffffff;
            background: rgba(0, 0, 0, 0.85);

            .content__header {
                font-weight: 500;
                margin-bottom: 13px;
                font-size: 14px;
            }

            .info {
                flex: 0 0 299px;
                padding: 0 25px;
                box-sizing: border-box;
                overflow-y: auto;
                overflow-x: hidden;
                scrollbar-width: thin;

                .name {
                    padding-top: 25px;
                    padding-bottom: 0;
                    font-weight: 500;
                    font-size: 16px;
                    margin-bottom: 32px;
                }

                .owner {
                    font-size: 14px;
                    margin-bottom: 32px;
                }

                .record {
                    display: flex;
                    gap: 10px;
                    margin-bottom: 32px;

                    &__preview {
                        width: 100%;
                        max-width: 90px;
                    }

                    &_fixHeight {
                        height: 176px;
                    }

                    &__info {
                        display: grid;
                        color: #ffffff;

                        &Name {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 16px;
                        }

                        &Duration {
                            margin-top: 5px;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 14px;
                        }
                    }

                    .preview {
                        width: 100%;
                        height: 100%;
                        position: relative;

                        & > img {
                            width: 100%;
                        }

                        &_clickable {
                            cursor: pointer;
                        }

                        & > .duration {
                            position: absolute;
                            background: #000000;
                            opacity: 0.8;
                            border-radius: 8px;
                            padding: 1px 5px;
                            font-weight: normal;
                            font-size: 12px;
                            right: 2px;
                            bottom: 7px;
                        }
                    }
                }

                .participants {
                    margin-bottom: 20px;
                }
            }

            .history {
                flex: 1 1 auto;

                & > aside {
                    height: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}
