.radioButton {
    display: flex;

    & > input {
        display: none;

        &:checked ~ label::before {
            background: #ffffff;
            border: 4px solid #2493fb;
        }
    }

    & > label {
        display: inline-block;
        cursor: pointer;
        position: relative;
        user-select: none;
        display: flex;
        align-items: center;

        &::before {
            content: '';
            display: inline-block;
            width: 14px;
            height: 14px;
            box-sizing: border-box;
            background: #ffffff;
            border: 1px solid #48587d;
            border-radius: 50%;
            margin-right: 8px;
        }
    }
}
