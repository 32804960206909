@import '../ConferencesList.module.scss';

.conference {
    display: grid;
    grid-template-columns: 2fr 1fr;
    position: relative;
    @include conference;
    &:hover {
        button {
            visibility: visible;
            opacity: 1;
        }
        [data-past-conf-controls] {
            transform: rotateX(0) translateY(0);
        }
        [data-past-conf-participants] {
            transform: rotateX(90deg) translateY(50%);
        }
    }

    @media (max-width: 992px) {
        grid-template-columns: 1fr;
        grid-gap: 10px;
        &:hover {
            button {
                position: relative;
                transform: scale(1);
                opacity: 1;
            }
        }
    }

    @media (max-width: 920px) {
        button {
            position: relative;
            transform: scale(1);
            opacity: 1;
        }
    }
    
    &__right {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 50px;
    }
}
