.root {
    width: 100%;
    height: 79px;
    display: flex;
    flex-direction: column;

    .buttons {
        display: flex;
        width: 100%;
        justify-content: space-around;
        height: 40px;
    }

    .videoDevice {
        img {
            height: 25px;
        }
    }
}

.participantsBtn {
    > *:last-child {
        right: 5px;
    }
}
