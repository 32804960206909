.listWrapper {
    position: absolute;
    width: 218px;
    bottom: 0;
    right: 0;
    background: #242424;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    &_down-left {
        top: 0;
        bottom: -100%;
        right: 20px;
        height: min-content;
        z-index: 5;
    }

    &_down-right {
        bottom: 100%;
        left: 0;
        right: 100%;
        height: min-content;
        z-index: 5;
    }
}

.list {
    display: flex;
    flex-direction: column;

    & .item {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        padding: 7px 0 7px 29px;
        cursor: pointer;
        text-align: left;
        &:hover {
            background: rgba(255, 255, 255, 0.05);
        }
        &_red {
            color: #d05353;
        }
        &:last-child {
            margin-bottom: 7px;
        }
        &:first-child {
            margin-top: 5px;
        }
        &_disable {
            color: gray;
            cursor: default;
            &:hover {
                background: none;
            }
        }
    }
}

.header {
    font-weight: bold;
    padding-left: 12px;
    padding-top: 12px;
    line-height: 16px;
    color: white;
}
