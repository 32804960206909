.chatHeader {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 16px;
    color: #adb9cf;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #ffffff;
        opacity: 0.06;
    }

    & .cross {
        position: relative;
    }
}

.exitBtn {
    width: auto;
    height: auto;
    z-index: 10;
}

.backBtn {
    z-index: 10;
}

.backImg {
    display: inherit;
    path {
        fill: #fff;
    }
}

.mobileTitle {
    color: #fff;
    flex-grow: 1;
    text-align: center;
    margin-right: 24px;
}
