.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    overflow: hidden;

    & .content {
        flex: 1;
        overflow-y: auto;
    }
}

.loader {
    margin: auto;
    &Wrapper {
        display: flex;
        height: 100%;
    }
}
