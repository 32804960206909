.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
    position: relative;
    color: #48587d;
    font-weight: 500;
    font-size: 20px;
}
