.block {
    display: flex;
    gap: 9px;
    margin-bottom: 32px;
    color: #ffffff;

    & .text {
        align-self: start;
        font-weight: 500;
        font-size: 14px;
        flex-basis: 213px;
        flex-shrink: 0;
        margin-right: 43px;
    }

    & > .fields {
        display: flex;
        font-weight: normal;
        font-size: 14px;
        margin-right: auto;
        align-items: center;
        max-width: 520px;
        width: 100%;
        flex-shrink: 0;

        & > .radioButton {
            margin-right: 27px;
        }

        & > span {
            margin: 0 8px;
        }
    }
}
