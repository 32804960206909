.inviteBtn {
    width: 129px;
    height: 37px;
    display: flex;
    align-items: center;
    position: relative;

    & .link {
        top: 45px;
    }

    .button {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 8px;
        height: 100%;
        margin-left: auto;
    }

    &_small {
        width: 125px;
        height: 29px;

        .button {
            padding: inherit;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 500;
        }
    }
}

.outsideClick {
    position: absolute;
    top: 0%;
    left: 0%;
}
