@mixin conference {
    max-width: 1100px;
    padding: 16px;
    margin-bottom: 37px;
    color: #646e8b;
    font-size: 16px;
    border: 1px solid #e9ebf5;
    transition: 0.2s border, 0.2s background;

    &:hover {
        border: 1px solid #cbe0ff;
    }
}