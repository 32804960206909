.menu {
    position: relative;
    font-size: 14px;
    line-height: 1em;
    white-space: nowrap;
}
.field {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;    
    color: #48587d;
    cursor: pointer;
    &::after {
        content: '';
        background: url('./images/arrow.svg');
        width: 8px;
        height: 4px;
    }
}
.list {
    position: absolute;
    margin-top: 10px;
    top: 100%;
    left: -10px;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    list-style: none;
    .item {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 10px;
        cursor: pointer;
    }
}
.field::before,
.list .item::before {
    content: '';
    width: 18px;
    height: 14px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}
*[data-lang='ru']::before {
    background: url('./images/ru.svg');
}
*[data-lang='en']::before {
    background: url('./images/en.svg');
}
