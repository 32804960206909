.wrapper {
    position: relative;
    &:hover {
        .tooltipLabel {
            visibility: visible;
            opacity: 1;
        }
    }
}

.imgWrapper {
    position: relative;
    margin-bottom: 4px;
    path {
        transition: fill 0.3s;
    }

    &_active {
        path {
            fill: #ff123d !important;
        }
    }

    &_disabled {
        &::after {
            content: '';
            position: absolute;
            width: 10px;
            height: 12px;
            right: -55%;
            bottom: 1px;
            background: url('../../../assets/LockGray.svg') center center no-repeat;
        }
        path {
            fill: #555555;
        }
    }
    &_processing {
        path {
            fill: #555555;
        }
    }
}

.tooltipLabel {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    bottom: calc(100% + 5px);
    left: 50%;
    width: inherit;
    padding-bottom: 4px;
    transform: translateX(-50%);
    transition: opacity 0.2s;
    font-size: 12px;
    font-weight: 400;
    font-family: Roboto;
    color: #fff;
    border-radius: 4px;
    text-align: center;
    z-index: 13;
}

.btn {
    &_disabled {
        color: #555555;
    }
    &_processing {
        pointer-events: none;
        cursor: default;
        color: #555555;
    }
}
