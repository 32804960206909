.form {
    &__notes {
        font-weight: normal;
        font-size: 12px;
        padding-left: 24px;
        margin-top: -8px;
        color: #a7a7a7;
    }

    &__submitErrorWrapper {
        max-width: 350px;
        margin: 0 auto 15px;
    }

    &__radioButton {
        margin-right: 27px;
    }

    &__input {
        border-radius: 8px;
        font-size: 14px;
        color: #48587d;
        max-width: 520px;
        height: 37px;
        padding: 10px 16px;
        border: 1px solid #48587d;

        &__statusText {
            position: absolute;
        }
    }

    &__footerButtons {
        display: flex;
        flex-wrap: wrap;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        gap: 40px;

        button {
            width: 210px;
            order: 2;

            &:not(:first-of-type) {
                margin-right: 20px;
                order: 1;
                color: #ffffff;
                background-color: transparent;
                border: 1px solid #ffffff;

                &:hover {
                    background-color: transparent;
                    border: 1px solid #ffffff;
                    color: #ffffff;
                }
            }
        }
    }

    &__fieldWithInput {
        & > div {
            width: 100%;
        }
    }
}

.text {
    align-self: center;
    font-weight: 500;
    font-size: 14px;
    flex-basis: 132px;
}

.noMarginBottom {
    margin-bottom: 0;
}
