@import '../../../../../styles/animations.scss';

.dialog {
    max-width: 380px;
    background: rgba(0, 0, 0, 0.85);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 28px 31px 30px 31px;
    position: absolute;
    font-weight: normal;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .field {
        .label {
            font-size: 16px;
            color: #ffffff;
        }
        .value {
            position: relative;
        }
    }
}

.header {
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
}

.link {
    padding: 0 15px;
    background-color: rgba($color: #ffffff, $alpha: 0.15);
    border-radius: 8px;
    height: 37px;
    color: #ffffff;
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 38px;

    &_hover:hover {
        & ~ div {
            @include stretchUpAndFadeIn_animate;
        }
    }
}

.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & .text {
        font-size: 16px;
        margin-top: 0;
    }

    & .toogle {
        flex-shrink: 0;
    }
}

.notify {
    position: absolute;
    bottom: -41px;
    left: 0;
    width: 100%;
}

.tooltipWrapper {
    position: absolute;
    bottom: 100%;
    @include stretchUpAndFadeIn_initial;
    left: 50%;
    top: -40px;
    > div {
        left: -50%;
    }
}
