.popup {
    height: 100%;
    width: 100%;
}
.header {
    padding: 13px 14px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;    

    & button {
        color: #2493fb;
        font-size: 14px;
        font-weight: 500;
        background-color: transparent;
    }

    &_disabled {
        & button {
            color: #777f95;
        }
    }
}
.form {
    margin-top: 42px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;

    .avatar {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 11px;

        &__delete {
            position: absolute;
            font-weight: 500;
            font-size: 14px;
            color: #ec6e6e;
            cursor: pointer;
            bottom: -35px;
            text-align: center;
        }

        &__file {
            display: none;
        }
        margin-bottom: 34px;
    }

    & input {
        background-color: #f8f9fc;
    }

    .block {
        margin-top: 18px;
    }

    .likeInput {
        border: none;
        border-top: 1px solid #e9ecf4;
        border-bottom: 1px solid #e9ecf4;
        font-weight: normal;
        font-size: 14px;
        padding: 0 14px;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        color: #48587d;
        box-sizing: border-box;
        width: 100%;
        align-items: center;
        height: 46px;
        background-color: #f8f9fc;

        &:not(:first-child) {
            margin-top: 8px;
        }

        &__button {
            color: #2493fb;
            font-size: 14px;
            background-color: transparent;
        }

        &__select {
            & > div:first-child {
                color: #2493fb;
                border: none;
                width: auto;
                padding: 0;

                & div:last-child {
                    display: none;
                }
            }

            & > div:nth-child(2) div {
                width: auto;
                right: -14px;
                max-width: 100vw;
            }
        }
    }

    .disabled {
        background-color: #f9f9f9;
        color: #777f95;
    }
}
.exit {
    padding: 14px;
    margin: 21px 0 18px;
}
.statusLabel {
    width: max-content;
    margin: 10px auto;
}
