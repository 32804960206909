.dialog {
    border-radius: 12px;
    padding: 28px 45px;
    box-sizing: border-box;
    opacity: 1;
    position: relative;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    &_light {
        background: #ffffff;

        .dialog__header span {
            color: #48587d;
        }
    }

    &_dark {
        background: #000000d9;
        color: #ffffff;
    }
}

.dialogIsScroll {
    .dialogContent {
        overflow: auto;
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 0, 0, 0.1) transparent;
    }
}

.cross {
    &_inside {
        position: relative;
    }

    &_outside {
        position: absolute;
        right: 10px;
        top: 10px;
    }
}
