@mixin full {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.root {
    @include full;
    z-index: 8;

    // OutsideClick div wrapper
    & > div:last-of-type {
        @include full;
    }
    .cross {
        position: absolute;
        top: 30px;
        right: 30px;
        z-index: 99;
    }
    .wrapper {
        box-sizing: border-box;
        background-color: #191919;
        height: 100%;
        display: flex;
        flex-direction: column;
        .player {
            @include full;
            flex-grow: 1;            
            margin: 20px;
            position: relative;
            video {
                @include full;                
                position: absolute;
                bottom: 106px;
                width: auto;
                height: auto;                
                min-width: 100%;
                min-height: 100%;
                max-width: 100%;
                max-height: 100%;
            }
        }
        .footer {
            height: 66px;
            width: 100%;
            display: flex;
            gap: 10px;
            background: #191919;
            padding: 10px 17px 10px 17px;
            box-sizing: border-box;
            justify-content: space-between;

            &__info {
                margin-right: 21px;
                font-weight: 500;
                font-size: 16px;
                color: #ffffff;
            }

            &__size {
                font-weight: normal;
                font-size: 14px;
                color: #777f95;
                white-space: nowrap;
            }

            &__download {
                display: inline-block;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: #ffffff;
                display: flex;
                align-items: center;
                cursor: pointer;

                & > img {
                    margin-right: 6px;
                }
            }
        }
    }
}
