.select {
    position: relative;
    cursor: pointer;

    & span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.activeValue {
    user-select: none;
}

.darkTheme {
    .selectedItem_default {
        background: rgba(0, 0, 0, 0.3);
        color: #ffffff;
        border: 1px solid transparent;
    }

    .items_default {
        background: #242424;
        color: #ffffff;
        border: 1px solid transparent;
        box-shadow: none;
    }

    .listOpen_default {
        border: 1px solid #ffffff;
    }
}

.blueTheme {
    .selectedItem_default {
        background: transparent;
        color: #2493fb;
        border: 1px solid transparent;
    }

    .items_default {
        background: #fff;
        color: #2493fb;
        border: 1px solid transparent;
        box-shadow: none;
    }

    .listOpen_default {
        border: 1px solid #2493fb;
    }
}

.selectedItem {
    &_default {
        background: transparent;
        border: 1px solid #48587d;
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        height: 37px;
        width: 300px;
        position: relative;
        justify-content: space-between;
    }

    &_new {
        background: transparent;
        border: 1px solid #e9ebf5;
        box-sizing: border-box;
        border-radius: 0;
        display: flex;
        align-items: center;
        padding: 0 16px;
        height: 37px;
        width: 300px;
        position: relative;
        justify-content: space-between;

        .activeValue {
            color: #48587D;
        }
    }

    &_mobile {
        background: #ffffff;
        border-top: 1px solid #e9ecf4;
        border-bottom: 1px solid #e9ecf4;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        position: relative;
        justify-content: flex-start;
        padding: 0 14px;
    }
}

.items {
    display: flex;
    background: #ffffff;
    border-radius: 8px;
    flex-direction: column;
    position: absolute;
    z-index: 10;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0px 2px 12px rgba(94, 110, 169, 0.4);

    &_default,
    &_new {
        top: 43px;
    }

    &_new {
        border-radius: 0;
    }

    &_mobile {
        top: 46px;
    }
}

.listOpen {
    &_default,
    &_new {
        border: 1px solid #2493fb;
    }
}

.arrowWrapper {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    right: -7px;
}

.arrow_relative {
    position: relative;
}
