.wrapper {
    margin-top: 6px;
    width: 324px;
}

.text {
    color: #777f95;
    font-weight: normal;
    font-size: 12px;
    margin-top: 3px;
}
