.wrapper {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 395px;
    margin: auto;
    padding: 40px 26px;
    background: rgba(0, 0, 0, 0.85);
    box-sizing: border-box;
    border-radius: 8px;

    label {
        color: #fff;
    }
}

.formDescription {
    margin-bottom:30px;
    color: #fff;
    text-align: center;
    font-weight: 500;
}

.registrationLink {
    margin: 0 auto;
    color: #fff;
    text-decoration: underline;
}