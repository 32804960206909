$horizontalMargin: 14px;

.form {
    width: 100%;
    height: 352px;
    background: transparent;
    border: none;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
    margin-top: 37px;
}

.text {
    margin: 0 $horizontalMargin 8px;
    font-weight: 500;
    font-size: 14px;
    color: #48587d;
    text-align: left;
}

.button {
    width: auto;
    border-radius: 8px;
    padding: 14px 0;
    margin: 0 $horizontalMargin;
}
.statusLabel {
    margin: 0 14px 20px 14px;
    width: auto;
}