.header {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: inherit;
    padding: 25px 30px;
    color: #fff;
    box-sizing: border-box;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    transition: opacity 0.3s;

    &_hidden {
        opacity: 0;
    }
}

.title {
    display: grid;
    place-content: center;
    margin: 0 auto;
    text-align: center;
}

.authorName {
    font-size: 12px;
    font-weight: 500;
}

.date {
    font-size: 12px;
    font-weight: 400;
}

.closeBtn {
    position: relative;
}

.download {
    display: inline-block;
    height: 16px;
    width: 16px;
    background: transparent;
    cursor: pointer;

    &_disabled {
        cursor: default;
    }
}

.spinner {
    & > div {
        border-left-color: #ffffff;
    }
}

.cross {
    position: relative;
    width: 16px;
    height: 16px;
    &::after,
    &::before {
        top: 50%;
        left: 50%;
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
    }

    &::after {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &::before {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}

.controls {
    display: flex;
    align-items: center;
    & > :first-child {
        margin-right: 40px;
    }
}