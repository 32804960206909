.wrapper {
    // max-width: 1440px;
    margin: 0 auto;
    position: relative;
    z-index: 6;
    min-height: 100%;
    user-select: none;
}

.height100vh {
    height: 100vh;
    overflow: hidden;
}

.peopleImg {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    text-align: center;
    user-select: none;

    @media screen and (max-height: 910px) {
        opacity: 0.3;
    }

    img {
        display: inline-block;
        max-width: 100%;
    }
}

.page {
    background: linear-gradient(180deg, #ffffff 49.48%, #e8eaff 100%);
    min-height: 100vh;
    // height: 100%;
    position: relative;
    z-index: 5;
    // min-width: 1000px;
    // overflow: hidden;
}

.vlTop,
.vlBottom {
    position: absolute;
    left: 50%;
    transform: translateX(25%);
    z-index: 2;

    @media screen and (max-height: 950px) {
        display: none;
    }
}

.vlTop {
    top: 248px;
}

.vlBottom {
    top: 402px;
}

.bcgNone {
    background: none;
}
