@import '../../styles/mediaQueries.scss';

.root {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #ffffff 49.48%, #e8eaff 100%);
    text-align: center;  

    > .block1 {
        position: relative;
        z-index: 5;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    > .block2 {
        position: relative;
        z-index: 1;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        height: 50%;
        .bgImage {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 50px;
            background: url('/assets/PeopleMobile.svg') no-repeat center bottom;
            background-size: contain;
        }
    }

    > button {
        width: 16px;
        margin: 53px 16px 0 16px;
    }

    div {
        h2 {
            font-weight: 500;
            font-size: 32px;
            line-height: 37px;
            color: #48587d;
            margin: 5px 20px;
        }
        h3 {
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            text-transform: uppercase;
            color: #777f95;
        }
    }
    .peopleImg {
        height: 100%;
    }
    .buttons {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        margin-top: auto;
        margin-bottom: 20px;
        z-index: 1;

        > button {
            margin: 7px 12px;
            width: calc(100% - 24px);
        }

        > div {
            display: flex;
            flex-direction: row;
            > button {
                margin: 7px 12px;
            }
        }
    }
    .logoWrapper {
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    .logo {
        width: 150px;
        height: 40px;
    }
    .qr {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 55px;
        right: 26px;
    }
    .title {
        padding-top: 20px;
    }
    @include media-landscape {
        flex-direction: row;
        > .block1 {
            width: 50%;
            height: 100%;
            .text {
                flex-grow: 1;
                padding-top: 30px;
            }
        }
        > .block2 {
            width: 50%;
            height: 100%;
            .bgImage {
                background-position: center center;
            }
            > .peopleImg {
                height: 60%;
            }
        }
    }
}
