.page {
    display: flex;
    background-color: #121213;
    height: 100vh;
}

.wrapper {
    margin: 0 auto;
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
    