.button {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 8px;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 9px 10px 7px 10px;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    border: none;
    height: 100%;
    min-height: 58px;

    & > .mobile {
        font-size: 12px;
    }
    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
}

.mobile {
    padding: 0;
    min-width: 0;
    min-height: 0;
}

.text {
    margin-top: auto;
    white-space: nowrap;
}
