.form {
    &__input {
        width: 100%;
        background: transparent;
        &Wrapper {
            max-width: 560px;
            margin-top: 15px;
            background: #fff;
        }
        &Label {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #9095b2;
        }
    }

    &__submit {
        margin-top: 15px;
        &Error {
            display: block;
            margin-top: 15px;
            color: #dd4e2e;
            font-size: 16px;
            text-align: center;
        }
    }
}
