.conferenceInfoFrame {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    max-width: 660px;
    max-height: 450px;
    padding: 20px;
    color:#fff;
    background: rgba(18, 18, 19, 0.85);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    transform: translateX(-50%) translateY(-50%);
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    z-index: 5;

    &__list {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 20px;
    }

    &__item {
        display: grid;
        grid-template-columns: 205px 1fr;
        column-gap: 100px;

        & > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__link{
        margin-top: 10px;
        padding: 0;
        color: #2493FB;
        font-size: 14px;
        line-height: 16px;
        background: transparent;
        border: none;
        cursor: pointer;
    }

    &__registeredOnly {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 37px;

        & > *:last-child {
            margin-top: 15px;
        }
    }
}