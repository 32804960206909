.chatMessage__infoStatus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 8px;
    img {
        position: relative;
        object-fit: cover;
    }

    &_delivered {
        position: relative;
        &::before {
            position: absolute;
            content: '';
            right: -5px;
            top: 0;
            width: 2px;
            height: 10px;
            background: #fff;
            border-radius: 5px;
            border: none;
            transform: translate(-3px, -1px) rotate(45deg);
        }
    }

    &_inProgress {
        display: none;
    }
}