.header {
    display: flex;
    color: #ffffff;
    padding: 12px 30px;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    min-height: 70px;
    box-sizing: border-box;
    transition: transform 0.3s;

    &_hidden {
        transform: translateY(-100%);
    }

    & > img {
        cursor: pointer;
    }

    &_audioFrames {
        position: absolute;
        width: 100%;
        z-index: 10;
        background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }

    &__conferenceName {
        white-space: nowrap;
        line-height: 32px;
        padding: 0 20px;
    }

    &__browserScreenBtn {
        flex-shrink: 0;
        margin-left: 25px;
        min-width: 20px;
    }

    &__left,
    &__right {
        display: flex;
        align-items: center;
    }

    &__right {
        margin-left: auto;
    }

    &__justifySpaceBetween {
        justify-content: space-between;
    }

    &__justifyEnd {
        justify-content: flex-end;
    }
}

.conferenceInfo {
    overflow: hidden;
    white-space: nowrap;
    margin: 0 10px;
    text-overflow: ellipsis;
}

.cross {
    margin-left: auto;
    &::after,
    &::before {
        height: 100%;
        left: 50%;
        top: 0;
    }
}
