.notification {
    max-width: 330px;
    position: absolute;
    right: 20px;
    top: 80px;
    z-index: 10;
    max-height: calc(100vh - 150px);
    overflow: hidden;
    & > div {
        margin-bottom: 10px;
    }
}