.form {
    color: #48587d;
    &_mobile {
        width: 100%;
        max-width: 500px;
        margin-top: 35px;
        text-align: left;
    }

    & button {
        margin: 18px 0;
    }

    input {
        margin-top: 6px;
        margin-bottom: 25px;
    }
}

a {
    text-decoration: none;
    color: #2493fb;
    font-size: 14px;
}

.label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #48587d;
    display: inline-block;

    &_mobile {
        margin-left: 15px;
        font-weight: 400;
        font-size: 12px;
        text-transform: uppercase;
    }
}

.inactiveButton {
    background-color: gray;
}

.avatarBlock {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.upload {
    font-size: 14px;
    margin-bottom: 25px;
    margin-top: 9px;
}

.avatar {
    align-self: center;
}

.fileAvatar {
    display: none;
}

.inputStyle {
    border-radius: 8px;
    height: 37px;
    font-size: 14px;
    padding: 10px 13px;
}
