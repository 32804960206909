.formSection {
    border: none;
    padding: 0;
    margin: 0;

    &:not(:first-child) {
        margin-top: 32px;
    }

    &__title {
        margin-bottom: 16px;
        font-weight: 700;
        font-size: 21px;
        line-height: 120%;
        color: #8690af;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }
}
