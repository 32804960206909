.wrapper {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 395px;
    box-sizing: border-box;
    border-radius: 8px;

    label {
        color: #fff;
    }
}

.formDescription {
    margin: 10px 0;
    color: #48587d;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
}

.registrationLink {
    margin: 10px 10px 15px;
    color: #2493fb;
    text-decoration: underline;
}
