.conference__info {
    display: flex;
    flex-direction: column;

    &Name {
        margin-bottom: 8px;
        cursor: pointer;
    }

    &List {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(65px, max-content));
        gap: 25px;
    }

    &Item {
        display: flex;
        align-items: center;
        gap: 6px;

        img {
            max-width: 17px;
        }
    }
}
