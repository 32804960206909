.react-datepicker-wrapper {
    visibility: hidden;
    transform: scale(0);
    position: absolute;
}

.react-datepicker-popper .react-datepicker {
    display: flex;
    -webkit-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.35);
    border: none;
    border-radius: 8px;
}


.react-datepicker .react-datepicker__triangle {
    display: none;
}

.react-datepicker__week {
    overflow: hidden;
}

.react-datepicker .react-datepicker__day--selected {
    border-radius: 50%;
    background: #2493fb;
}

.react-datepicker .react-datepicker__day--outside-month {
    pointer-events: none;
}

.react-datepicker .react-datepicker__day--outside-month .react-datepicker__dayNumber {
    color: #bac0ce;
}

.react-datepicker .react-datepicker__day--in-range {
    position: relative;
    color: #48587d;
    border-radius: 0 !important;
    background: #bac0ce;
    z-index: 1;
}

.react-datepicker .react-datepicker__day--in-range .react-datepicker__dayNumber {
    color: #48587d;
    background: #bac0ce;
    border-radius: 0;
}

.react-datepicker .react-datepicker__day--in-range:hover .react-datepicker__dayNumber {
    color: #fff;
}
.react-datepicker .react-datepicker__day--in-range .react-datepicker__dayNumber::before,
.react-datepicker .react-datepicker__day--in-selecting-range .react-datepicker__dayNumber::before {
    position: absolute;
    content: '';
    left: 10px;
    top: 0;
    height: 100%;
    width: 100%;
    background: #bac0ce;
    z-index: -2;
}

.react-datepicker .react-datepicker__day--today .react-datepicker__dayNumber {
    color: #2493fb;
}

.react-datepicker .react-datepicker__day--selected .react-datepicker__dayNumber {
    color: #fff;
}

.react-datepicker__dayNumber {
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 1;
}

.react-datepicker .react-datepicker__day--range-start .react-datepicker__dayNumber,
.react-datepicker .react-datepicker__day--range-end .react-datepicker__dayNumber {
    border-radius: 50% !important;
    background: #2493fb;
    color: #fff;
    z-index: 2;
}

.react-datepicker .react-datepicker__day--range-start {
    background: transparent;
}

.react-datepicker .react-datepicker__day--range-start:hover {
    background: transparent !important;
}

.react-datepicker .react-datepicker__day--range-end:hover {
    background: #bac0ce !important;
}

.react-datepicker .react-datepicker__day--range-end .react-datepicker__dayNumber::before {
    width: 70%;
    background: #fff;
}
.react-datepicker .react-datepicker__day:hover {
    border-radius: 50%;
    background: #2493fb;
}

.react-datepicker .react-datepicker__day:hover .react-datepicker__dayNumber {
    color: #fff;
}

.react-datepicker .react-datepicker__dayNumber {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    background: transparent;
}

.react-datepicker__month-container {
    position: relative;
    padding: 16px 36px;
    background: #fff;
    border-radius: 8px;
}

.react-datepicker > div:nth-child(2):after {
    position: absolute;
    right: 0;
    top: 16px;
    content: '';
    width: 1px;
    height: 90%;
    background: #bac0ce;
}

.react-datepicker__month-container .react-datepicker__header--custom {
    padding: 0;
    background: #fff;
}

.react-datepicker__headerTop {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 23px;
}

/* ------ Header label ------ */

.react-datepicker__headerTop_right .react-datepicker__current-month{
    margin-right: auto;
    margin-left: 0;
}

.react-datepicker__current-month {
    margin-left: auto;
    font-family: Roboto;
    line-height: 19px;
}

.react-datepicker .react-datepicker__current-month_center {
    margin: 0 auto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #48587D;
}

/* ------ /Header label ------ */



.react-datepicker__header--custom button {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    width: 17px;
    height: auto;
}



.react-datepicker__header--custom .react-datepicker__day-name {
    padding-bottom: 8px;
    color: #48587d;
    font-size: 14px;
    font-family: Roboto;
}

.react-datepicker .react-datepicker__day--in-selecting-range {
    background: transparent;
    z-index: 1;
}
.react-datepicker .react-datepicker__day--in-selecting-range .react-datepicker__dayNumber {
    position: relative;
    border-radius: 0;
    background: #bac0ce;
}

.react-datepicker
    .react-datepicker__day--selecting-range-start
    .react-datepicker__dayNumber:before {
    content: none;
}

.react-datepicker .react-datepicker__day--selecting-range-start {
    position: relative;
}

.react-datepicker .react-datepicker__day--selecting-range-start:before {
    position: absolute;
    content: '';
    left: 10px;
    top: 0;
    height: 100%;
    width: 100%;
    background: #bac0ce;
    z-index: -2;
}

.react-datepicker .react-datepicker__day--selecting-range-start .react-datepicker__dayNumber {
    color: #fff;
    background: #2493fb;
    border-radius: 50%;
}

.react-datepicker .react-datepicker__day--keyboard-selected {
    color: inherit;
    background: transparent;
}

/* ------ Header arrows ------ */
.react-datepicker__arrow {
    position: relative;
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    padding: 0;
    border: none;
    z-index: 1;
    text-indent: -999em;
    overflow: hidden;   
}

.react-datepicker__arrow_rotate {
    transform: rotate(180deg); 
}

/* ------ /Header arrows ------ */

