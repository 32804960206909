@import '../../../styles/animations.scss';

.text {
    display: none;
}
.error {
    color: #ff3055;
}
.warning {
    color: #bea461;
}
.show {
    display: flex;
    align-items: center;
    margin-top: 8px;
    font-size: 14px;
    @include fadeInWithScaleAnimation(0.2s);
}
.warningImg {
    width: 14px;
    height: 14px;
    margin-right: 4px;
}
