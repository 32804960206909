.container {
    display: flex;
    align-items: center;
    grid-area: days;
}

.checkbox {
    position: absolute;
    visibility: hidden;
    transform: scale(0);
}

.label {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 34px;
    height: 34px;
    color: #48587d;
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
    background: #e5e8ed;
    transition: background 0.3s, color 0.3s;
    cursor: pointer;

    &:not(:first-child) {
        margin-left: 10px;
    }

    &_checked {
        background: #2493fb;
        color: #ffffff;
    }
}
