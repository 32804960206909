.container {
    background-color: var(--ghost-white);
    border-radius: 36px;
    padding: 0 32px;

    &_withLoader {
        padding-bottom: 50px;
    }

    table {
        width: 100%;

        thead {
            position: sticky;
            top: 0;
            background-color: var(--ghost-white);
            z-index: 5;

            tr {
                padding-top: 32px;
                padding-bottom: 20px;
            }
        }

        tbody {
            tr {
                transition: background 0.2s;
                padding: 14px;
                border-radius: 45px;
                background: transparent;
                box-sizing: border-box;
                &:hover {
                    background: #ffffff;
                }
            }

            .loaderContainer {
                position: absolute;
                bottom: -40px;
                left: 50%;
                padding: 0;
                transform: translateX(-50%);

                &:hover {
                    background: transparent;
                }

                & > div {
                    position: relative;
                    transform: unset;
                    left: 0;
                    top: 0;
                }
            }
        }
    }

    &Head {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: var(--cool-grey);

        th {
            text-align: left;
        }
    }
}
