.modal {
    display: flex;
    flex-direction: column;
    max-width: 625px;
    padding: 25px 30px;
    background: #fff;
    border-radius: 36px;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        font-size: 21px;
        color: #363a53;
    }

    &__nav {
        display: flex;
        align-items: center;
        gap: 40px;
        margin-top: 25px;

        &Button {
            padding: 10px 16px;
            color: #9095b2;
            background: transparent;
            transition: background 0.2s, color 0.2s;
            &:hover {
                color: #fff;
            }
            &_active {
                color: #fff;
                background: #4176fa;
            }
        }
    }

    &__body {
        margin-top: 25px;
    }

    &__form {
        width: clamp(350px, 560px, 560px);
    }
}
