.form {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 73px;
    right: 0;
    overflow: auto;
    background-color: #f6f7fc;
    .buttons {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        padding: 13px 14px;
        z-index: 1;
        button {
            color: #2493fb;
            font-size: 14px;
            font-weight: 500;
            background-color: transparent;
        }
        button[disabled] {
            color: #777f95;
        }
    }
    .section {
        display: flex;
        flex-direction: column;
        .sectionTitle {
            padding: 20px 14px;
            font-size: 18px;
            color: #48587d;
        }
        .sectionTitle:first-child {
            margin-top: 0;
        }
        .likeInput {
            border: none;
            border-top: 1px solid #e9ecf4;
            border-bottom: 1px solid #e9ecf4;
            font-weight: normal;
            font-size: 14px;
            padding: 0 14px;
            background-color: #ffffff;
            display: flex;
            justify-content: space-between;
            color: #48587d;
            box-sizing: border-box;
            width: 100%;
            align-items: center;
            height: 46px;

            &_dark {
                color: #ffffff;
                background: #242527;
                border-top: 1px solid #373a3e;
                border-bottom: 1px solid #373a3e;
                &_disabled {
                    opacity: 0.5;
                }
            }

            &_disabled {
                background-color: #f9f9f9;
            }

            &:not(:first-child) {
                margin-top: 8px;
            }

            & input {
                border: none;
                padding: 0;
                font-size: 14px;
                font-weight: normal;
                height: auto;
                text-align: right;
                width: 85px;
            }

            & .duration {
                display: flex;
                align-items: center;

                & input {
                    width: 16px;
                }

                & span {
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }
            &__select {
                & > div:first-child {
                    color: #2493fb;
                    border: none;
                    width: auto;
                    padding: 0;
    
                    & div:last-child {
                        display: none;
                    }
                }
    
                & > div:nth-child(2) div {
                    width: auto;
                    right: -14px;
                    max-width: 100vw;
                }
            }
        }
        .inputBlock,
        .checkboxBlock {
            margin-bottom: 18px;
        }
        .checkboxBlock + .checkboxBlock {
            margin-top: -19px;
        }
    }
}
