.messagesCount {
    position: absolute;
    color: #45d05b;
    font-weight: 500;
    font-size: 14px;
    font-family: Seravek, sans-serif;
    right: 25px;
    top: 0;

    &_mobile {
        right: 0px;
        top: -5px;
        padding: 1px 2px;
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
        background: #d05353;
        border-radius: 50%;
    }
}

.wrapper {
    position: relative;
}
