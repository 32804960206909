.recordPlayer {
    width: 100%;
    height: 100vh;
    position: absolute;
    // top: 0;
    left: 0;
    background-color: #191919;
    z-index: 99;
}

.group {
    &__date {
        box-sizing: border-box;
        height: 27px;
        background-color: #f8f8f8;
        padding: 6px 14px;
        font-size: 12px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        color: #48587d;
        border-bottom: 1px solid #e9ecf4;
    }
}

.conference {
    display: flex;
    min-height: 61px;
    border-bottom: 1px solid #e9ecf4;

    &__info {
        flex: 1;
        display: flex;
        flex-direction: column;
        background-color: #f6f7fc;
        padding: 11px 14px 10px;

        .header {
            display: flex;
            justify-content: space-between;
            color: #48587d;
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 3px;
        }

        .footer {
            font-size: 14px;
            color: #777f95;
        }
    }
}
