.container {
    padding: 13px 14px 12px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #e9ecf4;

    .right {
        flex: 1;
        margin-left: 7px;
        font-size: 14px;

        .top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;

            & div:first-child {
                display: flex;
                align-items: center;
                color: #48587d;

                & .name {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    max-width: 120px;
                }
            }

            & div:last-child {
                color: #777f95;
            }
        }

        .bottom {
            color: #777f95;
        }
    }

    .inProgress {
        display: flex;
        align-items: center;
        
        & > span {
            margin-left: 8px;
        }
    }
}
