.inviteLink {
    display: flex;
    align-items: center;
    flex-basis: 100%;
    padding: 8px 12px;
    background: #f6f7fc;
    border: 1px solid #e9ebf5;
    border-radius: 4px;
    pointer-events: none;

    &__link {
        max-width: 290px;
        margin-right: auto;
        white-space: nowrap;
        color: #8690af;
        overflow: hidden;
        text-overflow: ellipsis;
        user-select: none;
        pointer-events: all;

        @media (max-width: 500px) {
            max-width: 120px;
        }
    }

    &__copyButton {
        pointer-events: all;
        display: flex;
        align-items: center;
        gap: 7px;
        color: #2493fb;
        font-size: 11px;
        font-weight: 500;

        img {
            max-width: 12px;
        }
    }
}
