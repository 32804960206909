.filter {
    display: flex;
    padding: 7px 13px;
    justify-content: space-between;
    border-bottom: 1px solid #e9ecf4;
    min-height: 42px;
    box-sizing: border-box;

    &__block {
        display: flex;

        &_spaceBetween {
            width: 100%;
            justify-content: space-between;
        }
    }

    &__item {
        padding: 5px 12px;
        background-color: #e9ecf4;
        color: #48587d;
        font-size: 14px;
        border-radius: 13px;
        cursor: pointer;

        &:last-child {
            margin-left: 8px;
        }

        &_active {
            background-color: #48587d;
            color: #ffffff;
        }

        &_cancel {
            background-color: transparent;
            color: #2493fb;
            font-weight: 500;
            font-size: 14px;
            padding: 0;
            display: flex;
            align-items: center;
        }
    }
}
