.dialog {
    background: rgba(18, 18, 19, 0.85);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    width: 327px;
}

.header {
    padding: 11px 0px 9px 13px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;

    & .info {
        margin-left: 9px;
        font-weight: 500;
        font-size: 14px;
    }
}

.footer {
    display: flex;
    flex-direction: row;
    height: 41px;

    & .allow,
    & .denied {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    & .allow {
        font-weight: 500;
        color: #84c17e;
        flex-basis: 234px;
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        &::before {
            content: url('../../../assets/CheckLightGreen.svg');
            margin-right: 7px;
        }

        &:hover {
            color: #7fcc78;
            &::before {
                content: url('../../../assets/CheckLighterGreen.svg');
            }
        }
    }

    & .denied {
        flex-grow: 1;
        color: #a3a3a3;
        display: flex;
    }
}
