.debugInfo {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-radius: 5px;
    background-color: rgba(80, 80, 80, 0.5);
}
.wrapper {
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    background-color: rgb(40, 40, 40);
    position: relative;

    .progress {
        position: absolute;
        background-color: rgb(40, 40, 40);
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        
        & > * {
            z-index: 5;
        }
    }
}
