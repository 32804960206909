.chatMessage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    color: #fff;
    a[href] {
        text-decoration: underline;
        color: #fff;
    }
    a[href]:hover {
        text-decoration: none;
    }

    &_img {
        position: relative;
    }
}

.messageContainer {
    display: flex;
    flex-direction: column;
    width: auto;
    margin: 0 auto 10px 0;
    padding: 10px 15px;
    background: rgba(255, 255, 255, 0.1);
    border-top-right-radius: 17px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    &_ownMessage {
        margin: 0 0 10px auto;
        background: #4598ff;
        border-top-right-radius: 0;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    &_ownImageMessage {
        padding: 0;
        margin: 0 0 10px auto;
        background: transparent;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &_emotion {
        background: none;
        padding: 10px 0px;
    }
}
