.link {
    color: #fff;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.text {
    max-width: 90%;
    word-break: break-word;
    font-size: 13px;
}

.imgContainer {
    display: flex;
    width: 100%;
    max-width: 260px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
        cursor: zoom-in;
    }
}

.mediaContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    max-width: 260px;
    align-items: center;
    overflow: hidden;
}

.file {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: min-content;
    gap: 0px 5px;
    margin: 5px 5px 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;

    &_image {
        height: 100%;
    }
    a {
        margin-right: 5px;
    }
    .size {
        opacity: 0.8;
        font-size: 12px;
        white-space: nowrap;
    }
}
