@import '../DynamicForm.module.scss';

.field {
    display: grid;
    grid-template-columns: 1fr 60px;
    column-gap: 80px;
    width: 100%;

    &__text {
        @include formBlockText;
    }

    &__title {
       @include formBlockTitle
    }

    &__description {
        @include formBlockDescription
    }
}
