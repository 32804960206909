.list {
    display: flex;
    flex-direction: column;
    max-width: 570px;
    max-height: 450px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #b7bbc0;
        outline: none;
        border-radius: 8px;
    }

    &__item {
        display: grid;
        grid-template-columns: 22px 1fr max-content;
        align-items: center;
        gap: 10px;
        padding: 20px 0;
        border-bottom: 2px solid #eeeeee;

        &Url {
            max-width: 90%;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #363a53;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &Controls {
            display: flex;
            gap: 25px;
        }

        &Button {
            padding: 0;
            height: max-content;
            background: transparent;
            &:enabled:hover {
                background: transparent;
            }
        }
    }
}
