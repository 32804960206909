.header {
    flex-direction: column;
    margin-bottom: 15px;

    &__top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        & > div {
            font-weight: 500;
            font-size: 14px;
            color: #2493fb;
            cursor: pointer;
        }
    }

    &__bottom {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

.datePicker {
    position: relative;
    display: flex;

    & > img {
        cursor: pointer;
    }

    & > div:first-of-type {
        cursor: pointer;
        position: absolute;
        top: 0;
        width: 24px;
        height: 27px;
        opacity: 0;
        visibility: visible;
        transform: scale(1);

        & div {
            height: 100%;
        }

        & input {
            width: 100%;
            height: 100%;
            cursor: pointer;
            border: none;
            padding: 0;
        }
    }

    &__calendar {
        flex-direction: column;
        border-radius: 12px 12px 0px 0px !important;
        height: 100%;

        & span {
            font-size: 16px;
            font-weight: 400;
            color: #48587d;
        }
    }

    &__popper {
        // It's necessary cause the component has inline styles
        inset: auto auto 0 0 !important;
        transform: none !important;
        width: 100%;
        height: 98%;

        & > div {
            height: 100%;
        }
    }

    .vertical {
        flex-direction: column;
    }
}
