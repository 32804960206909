.dialog {
    padding: 25px 32px;
    border-radius: 36px;

    &__header {
        font-weight: 500;
        font-size: 21px;
        color: #363a53;
    }

    &__description {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #9095b2;
    }
}

.form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &Wrapper {
        margin-top: 20px;
    }

    &__field {
        display: grid;
        grid-template-columns: 128px 430px;
        align-items: center;

        label {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: #9095b2;
        }
    }

    &__input {
        padding: 14px 25px;

        input {
            width: 100%;
        }

        &_error {
            border-color: #dd4e2e;
        }

        &::placeholder {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: #9095b2;
        }
    }

    &__submit {
        width: max-content;
        margin-left: auto;
        margin-top: 30px;
        padding: 8px 25px;
        background: #4176fa;
        border-radius: 20px;
        transition: 0.3s background;

        &Error {
            padding: 5px 0;
            color: #dd4e2e;
            text-align: center;
        }

        &Success {
            padding: 5px 0;
            color: #86daad;
            font-size: 16px;
            text-align: center;
        }
    }
}
