.form {
    max-width: 400px;

    &__body {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    &__input {
        min-width: auto;
        max-width: 270px;
        padding: 15px 25px;
        font-weight: 400;
        font-size: 18px;
        color: #363a53;
        border: 1px solid #d7d8e1;
        background: #fff;
    }
    &__inputLabel {
        font-weight: 400;
        font-size: 18px;
        color: #9095b2;
    }
    &__field {
        display: grid;
        grid-template-columns: 130px 1fr;
        gap: 5px;
        align-items: center;

        &_avatar {
            margin-bottom: 40px;
            input {
                display: none;
            }
        }
    }
    &__role {
        max-width: 270px;
    }
    &__submit {
        &SuccessMessage {
            margin: 25px auto;
            color: #fff;
            background-color: #86daad;
        }
    }
}

.role {
    &__admin {
        color: #dd4e2e;
    }
    &__participant {
        color: #9095b2;
    }
    &__organizer {
        color: #4176fa;
    }
}
