.form {
    color: #48587d;

    & button {
        margin: 18px 0;
    }

    .block > div {
        margin-top: 6px;
        margin-bottom: 25px;
    }
}

.inputStyle {
    border-radius: 8px;
    height: 37px;
    font-size: 14px;
    padding: 10px 13px;
}

.fail {
    margin-top: -19px;
    margin-bottom: 19px;
}
.info {
    margin-top: -19px;
    margin-bottom: 19px;    
    background: #ddf9de;
    color: #42703e;
}
p.info {
    padding: 10px;
}