:root {
  --ghost-white: #f9f9f9;
  --ultramarine-blue: #4176FA;
  --white: #fff;
  --charcoal: #363A53;
  --cool-grey: #9095B2;
  --columbia-blue: #cdd2e4;
  --pale-cornflower-blue: #adc0f1;
  --blueberry: #5989FF;
}
