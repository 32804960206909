.background {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 16;
}

.dialog {
    max-width: 604px;
    width: 100%;
    min-height: 155px;
    background: #ffffff;
    border-radius: 0;
    padding: 25px 21px 26px 29px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .description {
        max-width: 95%;
        margin-top: 16px;
        line-height: 24px;
        color: #646e8b;
    }

    &_dark {
        background: #000000;

        .description {
            color: #ffffff;
        }

        .cross div {
            &::after,
            &::before {
                background: #ffffff;
            }
        }

        .footer .exit {
            color: #ffffff;
            border-color: #ffffff;
            &:hover {
                background: transparent;
            }
        }
    }
}

.header {
    display: flex;
    font-size: 21px;
    font-weight: 500;
    color: #48587d;

    &__text {
        margin-right: 10px;
    }

    .cross {
        flex: 1;
        position: relative;

        & > div {
            position: relative;
            margin-left: auto;
            &::before,
            &::after {
                height: 100%;
                background: #aab1c6;
            }
        }
    }
}

.footer {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 40px;

    & > button {
        font-weight: 500;
        font-size: 14px;
    }

    .button {
        width: max-content;
        height: auto;
    }
}
