.buttonsGroup {
    display: flex;
}

.settings {
    margin-right: 54px;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #ffffff;
    & > img {
        margin-right: 5px;
    }
}