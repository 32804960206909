@import '../../../styles/mixins.scss';
@import '../../../styles/mediaQueries.scss';

.tabs {
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 25px;
    background: #ffffff;

    @include media-landscape {
        margin-top: 0;
    }

    &__controls {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 40px;
        border-bottom: 1px solid #E9ECF4;
    }

    &__controlBtn {
        margin: 0 auto;
        font-weight: 500;
        font-size: 14px;
        color: #BAC0CE;
        border-bottom: 1px solid transparent;
        border-radius: 0;
        span {
            margin-left: 5px;
        }

        &_active {
            color: #2493FB;
            border-bottom: 1px solid #2493FB;
        }
    }

    .participantsList {
        display: flex;
        flex-direction: column;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        height: auto;
        overflow-y: auto;
        @include scrollbar;
        text-align: left;
        padding: 0 20px;

        &_hidden {
            .participant {
                display: none;
            }
        }
    
        .creator {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
        }
    
        .participant {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
    
            &__info {
                font-size: 14px;
                line-height: 16px;
                color: #48587d;
                display: flex;
                width: 100%;
                justify-content: space-between;
                padding: 20px;

                div:last-child {
                    color: #BAC0CE;
                }
            }
        }
    }
}


