.toggle {
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    background-color: #bac0ce;
    flex-shrink: 0;

    &_active {
        background-color: #2493fb;

        &Green {
            background-color: #86daad;
        }
    }

    &_Orange {
        background-color: #ebac9e;
    }

    &_view {
        cursor: default;
    }

    &_disabled {
        background: rgba(186, 192, 206, 0.5);
        .ball {
            background: rgba(255, 255, 255, 0.5);
        }
    }

    &_default {
        width: 54px;
        height: 31px;
    }

    &_mobile {
        width: 39px;
        min-width: 39px;
        height: 25px;
    }
    &:hover {
        .tooltipLabel {
            visibility: visible;
            opacity: 1;
        }
    }
}

.ball {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50px;
    transition: left linear 0.2s;
    background-color: #ffffff;

    &_default {
        width: 25px;
        height: 25px;
        left: 3px;

        &_active {
            left: 26px;
        }
    }

    &_mobile {
        width: 21px;
        height: 21px;
        left: 2px;

        &_active {
            left: 16px;
        }
    }
}

.tooltipLabel {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    bottom: calc(100% + 5px);
    left: 50%;
    width: 200%;
    padding-bottom: 4px;
    transform: translateX(-50%);
    transition: opacity 0.2s;
    font-size: 12px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    color: #fff;
    border-radius: 4px;
    text-align: center;
    z-index: 13;
}
