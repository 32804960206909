.container {
    margin-top: 15px;
    font: 400 14px 'Roboto';
    line-height: 20px;
    color: #48587d;
    display: block;

    & > span {
        display: inline-block;
        margin-left: 13px;
        color: #2493fb;
        cursor: pointer;
    }
}
