.messageInfo {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: auto;
    margin-left: 10px;
    opacity: 1;

    &_opacity {
        opacity: 0.5;
    }

    &_insideContent {
        position: absolute;
        right: 10px;    
        bottom: 5px;
        padding: 5px 10px;
        border-radius:20px;
        opacity: 1;
        background: rgba(0, 0, 0, 0.6);
    }
}

.time {
    margin-right: 5px;
    font-size: 11px;
}