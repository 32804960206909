.popup {
    height: 100%;
    width: 100%;
}

.info {
    padding: 0 14px;

    & > div {
        margin-top: 5px;
    }
}

.dialog {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0;
    width: 100%;
    border-radius: 12px 12px 0px 0px;

    & > div:first-child {
        padding: 15px 14px 0px;
        margin-bottom: 30px;
    }

    &__header {
        font-size: 18px;
        font-weight: 500;
    }
}
