.conferenceInfo {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    color: #646e8b;
    font-size: 16px;
    font-weight: 400;
    overflow: hidden;
    > span {
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @media (max-width: 992px) {
        grid-area: info;
    }

    @media (max-width: 920px) {
        grid-area: info;
        justify-content: center;
    }

    &__name {
        font-size: 18px;
        font-weight: 500;
        color: #48587d;
        cursor: pointer;
    }

    &__description {
        margin-top: 4px;
    }

    &__schedule {
        display: grid;
        grid-template-columns: repeat(2, max-content);
        gap: 10px 26px;
        margin-top: 8px;

        @media (max-width: 992px) {
            grid-template-columns: repeat(auto-fit, minmax(100px, min-content));
        }

        &Date {
            display: flex;
            align-items: center;
            &::before {
                content: '';
                position: relative;
                display: inline-block;
                width: 15px;
                height: 100%;
                margin-right: 6px;
                background: url('../../../../../../../assets/CalendarNew.svg') center no-repeat;
            }
        }

        &Repeat {
            display: flex;
            align-items: center;
            &::before {
                content: '';
                position: relative;
                display: inline-block;
                width: 15px;
                height: 100%;
                margin-right: 6px;
                background: url('../../../../../../../assets/RepeatArrows.svg') center no-repeat;
            }
        }
    }

    &__scheduleRepeat {
        white-space: nowrap;
    }
}
