.form {
    width: 100vmin;
    > .block {
        display: flex;
        flex-direction: column;

        > .label {
            text-align: left;
            padding: 6px 14px;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            text-transform: uppercase;
            color: #48587d;
        }
    }

    > .buttons {
        margin: 20px 10px;
    }
}
