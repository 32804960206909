@import '../../../styles/animations.scss';

.log {
    width: 327px;
    position: absolute;
    right: 20px;
    bottom: 80px;
    z-index: 9;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    & > div {
        margin-top: 10px;
        padding: 3px 10px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 15px;
        height: 22px;
        font-size: 14px;
        color: #ffffff;
        line-height: 22px;
        & > span {
            font-weight: 500;
        }
    }
}

.itemFadeIn {
    @include fadeInAnimation(0.2s);
}

.itemFadeOut {
    @include fadeOutAnimation();
}
