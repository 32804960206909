.settings {
    &__header {
        margin: 0;
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        color: #9095b2;
    }
    &__body {
        height: 100%;
        padding: 20px 40px;
        background: #f9f9f9;
        border-radius: 36px;
    }
}
