.page {
    &__header {
        display: flex;
        align-items: center;

        h1 {
            font-weight: 700;
            font-size: 32px;
            color: #9095b2;
        }
    }
    &__userName {
        margin-left: 30px;
        font-weight: 700;
        font-size: 32px;
        color: #363a53;
    }
    &__body {
        margin-bottom: 20px;
        padding: 30px 40px;
        border-radius: 36px;
        background: #f9f9f9;
    }

    &__formControls {
        margin-left: auto;
    }
}
