.nav {
    box-sizing: inherit;
    width: 100%;
    max-width: 325px;
    padding: 25px;
    border-radius: 36px;
    background-color: var(--ghost-white);
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    flex-direction: column;

    &__items {
        margin-top: 90px;
    }

    &__logo {
        display: flex;
        max-width: 180px;
        margin: 0 auto;

        img {
            width: 100%;
        }
    }
}

.item {
    display: flex;
    align-items: center;
    padding: 13px 22px;
    gap: 10px;
    border-radius: 30px;
    margin-bottom: 20px;

    &:hover {
        background-color: var(--white);
        cursor: pointer;
    }

    &.exit {
        margin-top: auto;
        margin-bottom: 0;
        color: var(--cool-grey);
    }

    &.active {
        background-color: var(--ultramarine-blue);
        color: var(--white);
    }
}
