.header {
    position: relative;
    height: 82px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 15px 15px;
    box-sizing: border-box;
    border-bottom: 1px solid #e9ecf4;
    > span {
        position: absolute;
        top: 6px;
        left: 6px;
        right: 6px;
        text-align: right;
        font-size: 8px;
        color: rgba(200, 200, 200, 0.8);
        user-select: text;
    }
}
