.block {
    display: flex;
    flex-direction: column;

    .annotation {
        padding: 0 0 7px 14px;
        color: #48587d;
        text-transform: uppercase;
        font-weight: normal;
        font-size: 12px;
    }
}
