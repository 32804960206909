@import '../../../styles/animations.scss';

.label {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 100%;
    font-size: 14px;
    padding: 8px;
    box-sizing: border-box;
    transform-origin: top;
    background-color: #ffeded;
    color: #b7384e;
    @include fadeInWithScaleAnimation(0.2s);
}

.itemFadeOut {
    @include fadeOutWithScaleAnimation(0.2s);
}