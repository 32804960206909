.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.container {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    padding: 0 14px 11px 4px;
    border-radius: 8px;
    border: 1px solid #48587d;
    overflow: hidden;
    flex-wrap: wrap;
    box-sizing: border-box;

    &_focus {
        border: 1px solid #2493fb;
    }

    & > li {
        height: min-content;
        margin-left: 10px;
        margin-top: 10px;
    }
}

.info {
    &__container {
        display: flex;
        align-items: center;
        margin-top: 12px;
    }

    &__text {
        margin-left: 10px;
        font-size: 12px;
        color: #777f95;
    }
}

.error {
    color: red;
}

.fakeInput {
    width: 10px;
    height: 25px;
    border: none;
    outline: none !important;
}

.placeholder {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #bac0ce;
}

.tag {
    display: flex;
    align-items: center;
    width: max-content;
    padding: 4px 8px;
    color: #48587d;
    background: #daedff;
    border-radius: 4px;
}

.cross {
    position: relative;
    width: 12px;
    height: 12px;
    background: transparent;
    border: none;
    padding: 0;
    margin: auto 8px;
    cursor: pointer;

    &::after,
    &::before {
        content: '';
        position: absolute;
        left: 50%;
        width: 100%;
        height: 1px;
        background: #6a78a4;
    }

    &::after {
        transform: translateX(-50%) rotate(45deg);
    }

    &::before {
        transform: translateX(-50%) rotate(-45deg);
    }
}
