.connectionInfo {
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
    width: 100%;
    max-width: 350px;
    padding: 24px 24px 27px;
    box-shadow: 0 0 25px #e8eaf2;
    display: flex;
    flex-direction: column;
    color: #48587d;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #e9ebf5;
        outline: none;
        border-radius: 8px;
    }

    button {
        margin: 24px 0;
    }

    &__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
        margin-bottom: 8px;
    }

    &__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 16px;
    }

    &__value {
        display: flex;
        border: 1px solid #e9ebf5;
        background: #f6f7fc;
        border-radius: 4px;
        color: #2493fb;
        padding: 8px 12px;
        gap: 10px;
        justify-content: space-between;
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }

        span {
            flex-grow: 0;
            white-space: nowrap;
            overflow: hidden;
            color: #8690af;
            text-overflow: ellipsis;
        }

        div {
            display: flex;
            gap: 5px;
            cursor: pointer;
            font-weight: 500;
            font-size: 11px;
            align-items: center;
            img {
                display: inline-block;
                width: 11px;
                height: 13px;
            }
        }
    }
}
