@import '../../styles/mediaQueries.scss';

.page {
    background: #f6f7fc;
    &__header_custom {
        padding: 53px 12px 0;
        box-sizing: border-box;
        & > button:last-child {
            width:auto;
        }
    }
}

.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    @include media-landscape {
        display: grid;
        grid-template-columns: 1fr 1fr;
        flex-direction: unset;
    }
}

.timerWrapper {
    font-size: 14px;
    font-weight: 400;
    color: #8e94a4;
}

.linkWrapper {
    margin: 15px auto 0;
    font-size: 14px;

    & > div:first-child {
        justify-content: center;
    }
}

.conferenceName {
    display: block;
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 500;
    color: #48587d;
}

.changeConferenceSettingsBtn {
    margin-left: auto;
}
