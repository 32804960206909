.input {
    border: 1px solid #48587d;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 12px 22px;
    font-size: 16px;
    height: 43px;
    font-weight: 400;
    width: 100%;
    color: #48587d;

    &:focus {
        outline: none;
        border-color: #2493fb;
    }

    &:invalid {
        border-color: red;
    }

    &::placeholder {
        color: #bac0ce;
    }

    &.input_disabled {
        background-color: #f9f9f9;
        color: #777f95;

        & > input {
            color: #777f95;
        }
    }

    &_new {
        font-weight: 400;
        font-size: 16px;
        color: #48587d;
        border-radius: 0;
        border: 1px solid #e9ebf5;
        background: transparent;

        &::placeholder {
            font-weight: 400;
            font-size: 16px;
            color: #8690af;
        }

        &.input_disabled {
            opacity: 0.5;
        }
    }

    &_mobile {
        height: 46px;
        border: 1px solid #e9ecf4;
        border-left: 0;
        border-right: 0;

        &.input_disabled {
            background-color: #f9f9f9;
            color: #777f95;

            & > input {
                color: #777f95;
            }
        }
    }

    &_mobileDark {
        height: 46px;
        color: #ffffff;
        border-top: 1px solid #373a3e;
        border-bottom: 1px solid #373a3e;
        background-color: #242527;

        &::placeholder {
            color: #ffffff;
        }

        &.input_disabled {
            opacity: 0.5;
            background-color: #242527;
            color: #ffffff;
        }
    }
}

.inputBlock {
    position: relative;

    & .error {
        border-color: #ff123d;

        &_text {
            color: #ff123d;
            &::placeholder {
                color: #ff123d;
            }
        }
    }

    & .warning {
        border-color: #bea461;

        &_text {
            color: #bea461;
            &::placeholder {
                color: #bea461;
            }
        }
    }

    & .input_disabled {
        background-color: #eef0f5;
        border: 1px solid #bac0ce;
        color: #989fad;
    }
}

.mobileInputBlock {
    position: relative;

    & .error {
        border-color: #ff123d;

        &_text {
            color: #ff123d;
            &::placeholder {
                color: #ff123d;
            }
        }
    }

    & .warning {
        border-color: #bea461;

        &_text {
            color: #bea461;
            &::placeholder {
                color: #bea461;
            }
        }
    }

    > input {
        padding: 12px 14px;
        font-size: 14px;
        border-radius: 0;
    }
}

.statusText {
    white-space: nowrap;
}
