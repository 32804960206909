.input {
    font-weight: normal;
    font-size: 14px;
    color: #48587d;
    border-color: #48587d;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 37px;
}

.wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    .background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: #000000;
        opacity: 0.2;
        z-index: 15;
    }

    .form {
        width: 604px;
        font-size: 14px;
        background: #ffffff;
        border-radius: 12px;
        box-sizing: border-box;
        padding: 25px 21px 0 29px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 16;

        .note {
            font-size: 16px;
            color: #48587d;
            font-weight: normal;
            margin-bottom: 18px;
            max-width: 90%;
            line-height: 22.4px;
        }

        .block {
            &_last {
                margin-top: 24px;
                margin-bottom: 29px;
            }
        }

        .label {
            font-weight: 500;
            font-size: 14px;
            color: #48587d;
            margin-bottom: 6px;
        }

        .cross {
            position: absolute;
            right: 21px;
            top: 25px;
        }

        .buttons {
            display: flex;
            margin: 30px 0;
            justify-content: flex-end;

            & > button {
                width: max-content;
                height: 32px;
                font-weight: 500;
                font-size: 14px;

                &:last-of-type {
                    margin-left: 10px;
                }
            }

            &_save {
                padding: 0 22px;
            }

            &_cancel {
                padding: 0 17px;
            }
        }
    }
}
