.root {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #ffffff 49.48%, #e8eaff 100%);
    text-align: center;
    align-items: center;

    div {
        h2 {
            font-weight: 500;
            font-size: 32px;
            line-height: 37px;
            color: #48587d;
            margin: 5px 20px;
        }
        h3 {
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            text-transform: uppercase;
            color: #777f95;
        }
    }
    &__header {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 53px 8px 0 8px;

        > .logo {
            flex-grow: 1;
            img {
                width: 120px;
                height: 25px;
            }
        }

        > button {
            width: 32px;
            height: 32px;
        }
    }
}

.title {
    flex-grow: 1;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #48587d;
    white-space: nowrap;
    text-overflow: ellipsis;
}
