.desktop {
    position: relative;
    width: 100%;
    height: 100%;
}

.mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
}
