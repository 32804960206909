.container {
    display: grid;
    justify-content: center;
    align-content: end;
    height: 100vh;
    padding-bottom: 54px;
    box-sizing: border-box;
    background: linear-gradient(180deg, #ffffff 49.48%, #e8eaff 100%);
}

.description {
    margin-bottom: calc(100% - 70% - 10%);
    font-size: 14px;
    font-weight: 500;
    color: #48587d;
    text-align: center;
}

.img {
    width: 100%;
}
