.schedule {
    &_mobile {
        height: 100%;
    }
}

.header {
    display: flex;
    align-items: center;
    min-height: 40px;
    position: relative;
    color: #48587d;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 39px;
}

.arrow {
    position: static;
    margin: 0 13px;
}
