.participants {
  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    margin-bottom: 8px;
  }

  .tabs {
    li {
      margin-bottom: 0;
    }
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    .listItem {
      display: flex;
      width: fit-content;
      align-items: center;
      gap: 15px;
      border-radius: 41px;
      padding: 8px 12px;
      font-size: 16px;
      line-height: 150%;
      font-weight: 400;
      color: #48587D;
      background-color: #F6F7FC;
    }

    .creator {
      background-color: #6A78A4;
      color: #fff;
      font-weight: 500;

      & ~ div {
        font-weight: 500;
        color: #8690AF
      }
    }
  }

  .participantsFlex {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 12px;

    & > div {
      flex-grow: 1;
      &::before {
        content: "";
        position: absolute;
        left: 14px;
        top: 14px;
        background: url(/assets/Search.svg) center center no-repeat;
        width: 14px;
        height: 14px;
        background-size: cover;
      }
    }
  }

  input {
    border: 1px solid #E9EBF5;
    background-color: #F6F7FC;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    height: 43px;
    padding: 8px 8px 8px 40px;
    margin-bottom: 16px;
    outline: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #48587D;

    &::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #8690AF;
    }

    &:focus {
      border: 2px solid #2493FB;
    }
  }
}
