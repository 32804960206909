.container {
    display: grid;
    grid-template-columns: 1fr 350px;
    gap: 30px;
    height: 100%;

    .content {
        box-sizing: border-box;
        width: 100%;
        color: #48587d;
        overflow-y: auto;
        height: 100%;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 10px;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: #E9EBF5;
            outline: none;
            border-radius: 8px;
        }

        &__title {
            font-weight: 500;
            font-size: 27px;
            line-height: 120%;
            margin-bottom: 16px;
        }

        &__date {
            font-weight: 500;
            font-size: 18px;
            line-height: 120%;
            margin-bottom: 16px;
        }

        button {
            width: fit-content;
            display: flex;
            align-items: center;
            gap: 7px;
            margin-bottom: 16px;

            &:hover {
                img {
                    filter: brightness(0) invert(1);
                }
            }
        }

        &__controls {
            display: flex;
            align-items: center;
            gap: 16px;
        }

        .line {
            border-bottom: 1px solid #e9ebf5;
            margin-bottom: 16px;
        }

        .detailsBlock {
            margin-bottom: 24px;

            &__title {
                font-weight: 500;
                font-size: 18px;
                line-height: 120%;
                margin-bottom: 8px;
            }

            & > *:not(:first-child) {
                display: flex;
                align-items: baseline;
                margin-bottom: 8px;
            }

            img {
                margin-right: 7px;
            }
        }

        .participants {
            &__title {
                font-weight: 500;
                font-size: 18px;
                line-height: 120%;
                margin-bottom: 8px;
            }
        }
    }
}
