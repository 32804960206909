.settingsNavigation {
    display: flex;
    gap: 50px;
    margin-top: 20px;
    margin-left: 40px;

    &__item {
        position: relative;
        padding: 10px 0;
    }

    &__link {
        display: flex;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #363a53;
        transition: color 0.2s;

        &:hover {
            color: #4176fa;
        }

        &_active {
            color: #4176fa;

            &::after {
                content: '';
                position: absolute;
                top: 100%;
                display: block;
                width: 100%;
                height: 2px;
                background: #4176fa;
            }
        }
    }
}
