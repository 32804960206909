.navigationList {
    display: flex;
    justify-content: flex-start;
    gap: 25px;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 44px;

    a {
        position: relative;
        color: #8690af;
        transition: color 0.2s;
    }

    &__item {
        &_active {
            a {
                color: #2493fb;
                &::after {
                    content: '';
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #2493fb;
                }
            }
        }
    }
}
