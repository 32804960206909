@import '../../../styles/mediaQueries.scss';

.root {
    width: 100vmin;

    .name {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 140%;
        color: #48587d;
        text-align: left;
        padding: 22px 14px 32px 14px;

        @include media-landscape {
            padding: 0 14px 0 14px;
        }
    }

    .annotation {
        text-align: left;
        padding: 6px 14px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;

        color: #48587d;
    }

    > div {
        > button {
            margin: 30px 16px 16px 16px;
            width: calc(100% - 32px);
        }
    }

    > .toggle {
        text-align: left;
        display: flex;
        flex-direction: row;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        background-color: white;
        color: #48587d;
        border: 1px solid #e9ecf4;
        border-left: 0;
        border-right: 0;
        align-items: center;
        > span {
            width: calc(100% - 100px);
            padding: 15px 15px;
        }
        > div {
            width: 30px;
        }
    }
}

.field {
    &_error {
        > input {
            border-color: #ff123d;
        }
    }
}

.fail {
    margin: auto auto 10px;
    width: 90%;
}
