.buttons {
    display: flex;
    overflow: hidden;
    transition: width 0.3s linear;
    width: 0px;

    &_open {
        width: 80px;
    }

    &_open_scheduled {
        width: 160px;
    }

    & > div {
        flex: 1 0 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        height: 100%;
        cursor: pointer;

        & > img {
            margin-bottom: 6px;
        }
    }

    &__edit {
        background-color: #2493fb;
        color: #ffffff;
    }

    &__delete {
        background-color: #ffdad1;
        color: #d05353;
    }
}

@keyframes opening {
    0% {
        width: 0px;
    }
    100% {
        width: 160px;
    }
}

@keyframes closing {
    0% {
        width: 160px;
    }
    100% {
        width: 0px;
    }
}
