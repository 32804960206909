.layout {
    box-sizing: border-box;
    height: 100vh;
    padding: 40px;
    display: flex;
    font-family: Inter, sans-serif;
    color: var(--charcoal);
    gap: 40px;
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;

    & > *:last-child {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
}

.loaderWrapper {
    display: flex;
    height: 100vh;
    & > * {
        margin: auto;
    }
}
