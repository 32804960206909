.page {
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 80px);

    & > *:last-child {
        height: 100% !important;
        overflow: auto;
    }

    tbody {
        font-size: 16px;
        line-height: 19px;

        tr {
            &:last-child {
                margin-bottom: 0;
            }

            td {
                display: flex;
                align-items: center;
            }
        }
    }
}



.header {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 35px;

    &__title {
        margin: 0;
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        color: #9095b2;
    }

    &__inviteBtn {
        width: max-content;
    }

    &__filterWrapper {
        margin-left: auto;
    }
}

.table {
    &__name {
        display: flex;
        align-items: center;
        gap: 12px;
        font-weight: 600;
        color: var(--charcoal);
    }
    &__date {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #9095b3;
    }
    &__active {
        padding: 4px 10px;
        color: #fff;
        font-weight: 500;
        font-size: 14px;
        border-radius: 14px;

        &_green {
            background: #86daad;
        }
        &_orange {
            background: #ebac9e;
        }
    }
    &__role {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;

        &_participant {
            color: #9095b2;
            &::before {
                content: '';
                display: inline-block;
                width: 13px;
                height: 17px;
                margin-right: 10px;
                background: url('../../assets/Participant.svg') center no-repeat;
                background-size: cover;
            }
        }

        &_organizer {
            color: #4176FA;
            &::before {
                content: '';
                display: inline-block;
                width: 18px;
                height: 18px;
                margin-right: 10px;
                background: url('../../assets/Organizer.svg') center no-repeat;
                background-size: cover;
            }
        }

        &_admin {
            color: #DD4E2E;
            &::before {
                content: '';
                display: inline-block;
                width: 16px;
                height: 21px;
                margin-right: 10px;
                background: url('../../assets/Admin.svg') center no-repeat;
                background-size: cover;
            }
        }
    }
}
