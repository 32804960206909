.footer {
    margin: 5px;
    display: flex;
    align-items: center;
    transition: transform 0.3s;
    &_hidden {
        transform: translateY(100%);
    }

    &_withPriorityFrame {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 4;
        margin: 0;
        padding: 5px;
        box-sizing: border-box;
        background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }

    &__videoDevice {
        img {
            height: 25px;
        }
    }
}

.left {
    display: flex;
    align-items: center;
    margin-right: 15px;
    & > div {
        margin: 0 10px;
        width: 109px;
        border-radius: 8px;
        height: 58px;
        box-sizing: border-box;
    }
}

.center {
    display: grid;
    grid-template-columns: minmax(100px, 410px) repeat(2, minmax(109px, 1fr));
    grid-template-rows: 58px;
    place-content: center;
    flex-basis: 576px;
    gap: 0 10px;
    margin: auto;
    z-index: 6;

    @media (max-width: 1250px) {
        gap: 0 0;
    }

    @media (max-width: 1250px) {
        grid-template-columns: repeat(2, max-content);
        gap: 20px;

    }

    @mixin withNoInput() {
        grid-template-columns: 0 minmax(max-content, 109px);
        grid-template-rows: 58px;
        margin: 0 10px;
        @media(max-width: 1450px) {
            margin: 0;
        }
    }

    &_withChatOpen {
        @include withNoInput;
        grid-template-columns: repeat(2, minmax(max-content, 109px));
        @media(max-width: 1450px) {
            grid-template-columns: repeat(2, max-content);
        }
    }

    &_withLeftBarOpen {
        @media(max-width: 1450px) {
            @include withNoInput;
            grid-template-columns: 0 repeat(2, max-content);
        }
        @media (max-width: 1250px) {
            grid-template-columns: repeat(2, max-content);
        }
    }
}

.right {
    display: flex;
    align-items: center;
    & > div {
        margin: 0 42px;
        width: 109px;
        border-radius: 8px;
        height: 58px;
        box-sizing: border-box;
    }

    & .button {
        background-color: #d05353;
        border-radius: 8px;
        width: 129px;
        height: 37px;
        margin-right: 10px;
        &:hover {
            background-color: #d05353;
        }
    }

    @media screen and (max-width: 1500px) {
        & > .withoutMargin {
            margin: 0;
        }
    }
}
