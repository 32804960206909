.layoutSelect {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    & [data-js-select-header] {
        border: 0;
    }

    > div {
        justify-content: center;
        width: 100%;
        > span {
            text-align: right;
        }
    }
}
