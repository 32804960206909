.form {
    width: 394px;
    height: 405px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.85);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 6;
    color: #ffffff;
    padding: 45px 41px 32px;
    text-align: center;

    & > img {
        margin-bottom: 26px;
    }

    & > .block {
        margin-top: 27px;
        text-align: left;
        margin-bottom: 27px;
        & > .text {
            margin-bottom: 8px;
        }
    }
}

.link {
    margin-top: 44px;
    font-weight: 500;
    font-size: 14px;
    border-bottom: 1px solid #ffffff;
    color: #ffffff;
    display: inline-block;
}

.inputStyle {
    border-radius: 8px;
    height: 37px;
    font-size: 14px;
    padding: 10px 13px;
}

.button {
    border-radius: 8px;
    height: 37px;
}
