.wrapper {
    border: 1px solid var(--columbia-blue);
    box-sizing: border-box;
    width: 100%;
    min-width: 270px;
    border-radius: 25px;
    padding: 12px 12px;
    cursor: text;
    display: flex;
    align-items: center;
    gap: 8px;

    &.active {
        border-color: var(--ultramarine-blue);
    }
}

.input {
    border: none;
    outline: none;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: var(--charcoal);

    &[disabled] {
        opacity: 0.4;
    }

    &::placeholder {
        color: var(--cool-grey);
    }
}
