.hoverWrapper {
    display: block;
    position: absolute;
    bottom: 93%;
    left: 50%;
    transform: translateX(-50%) translateY(-15%);
    white-space: nowrap;
    padding: 10px;
}

.deviceLabel {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
