.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 100;

    &_light {
        background: rgba(0, 0, 0, 0.2);
    }

    &_dark {
        background: #00000066;
    }

    &_dark1{
        background: rgba(0, 0, 0, 0.8);
    }
}