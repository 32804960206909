.datePicker {
    position: relative;
    display: flex;
    margin: 0 12px;

    & > img {
        cursor: pointer;
    }

    & > div:first-of-type {
        cursor: pointer;
        position: absolute;
        left: -10px;
        top: 0;
        width: 38px;
        opacity: 0;
        visibility: visible;
        transform: scale(1);

        & input {
            width: 100%;
            cursor: pointer;
        }
    }

    & span {
        font-size: 16px;
        font-weight: 400;
        color: #48587d;
    }
}
