.dialog {
    padding: 49px 25px 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.85);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    width: 394px;

    & .button {
        background-color: #d05353;
        border-radius: 8px;
        height: 37px;
        font-size: 14px;

        &:not([disabled]):hover {
            background-color: #b84848;
        }
    }

    & .cross {
        position: absolute;
        right: 19px;
        top: 19px;
    }
}

.marginB {
    margin-bottom: 20px;
}

.text {
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
}

.cancel {
    display: inline-block;
    margin-top: 23px;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
    cursor: pointer;

    &:hover {
        border-color: transparent;
    }
}
