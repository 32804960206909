.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 14px;
    font-size: 14px;
    line-height: 20px;
    background-color: #fff;
    color: #48587d;
    cursor: pointer;
    &Add {
        color: #2493FB;
    }
    &Change {
        text-transform: lowercase;
    }
    &Change::after {
        content: '';
        background: url('./images/arrow.svg') no-repeat 0 0;
        width: 8px;
        height: 14px;
    }
}
.win {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;    
    overflow: auto;
    padding: 62px 0;    
    background: #fff;    
    color: #48587D;
    &Header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 14px 28px 14px;
        a {
            color: #2493FB; 
        }
        &Cancel {
        }
        .winNotChanged &Ready {
            color: #ccc;
            cursor: default;
        }        
    }
    .fields {
        display: flex;
        flex-direction: column;        
        gap: 14px;
        .field {
            margin: 0 14px;
        }
        .field input {
            margin: 0 -14px;
            width: 100vw;            
        }
        .field .btn {
            display: block;
            box-sizing: border-box;            
            padding: 14px;
            text-align: center;
            background: #2493FB;
            border-radius: 12px;
            color:#fff;
            font-weight: 500;
            font-size: 14px;
            line-height: 140%;
            width: calc(100vw - 28px);
        }
    }
    .list {
        margin: 0 14px;
        &Title {
            padding: 8px 0;
            font-size: 12px;
            line-height: 14px;
            text-transform: uppercase;        
        }
        &Item {
            display: flex;
            justify-content: space-between;
            margin: 0 -14px;
            padding: 12px 14px;
            background: #fff;
            border-top: solid 1px #E9ECF4;
            &Remove {
                background: url('./images/remove.svg') no-repeat 0 0;
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
        &Item:last-child {
            border-bottom: solid 1px #E9ECF4;
        }
    }
}
.tipWrap {
    position: relative;
}
.tip {
    position: absolute;
    padding: 12px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    background: #fff;
    top: 46px;
    right: -12px; 
    left: -12px;
    &Item {
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 8px 0
    }
}