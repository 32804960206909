.settings {
    color: #646e8b;

    &__header {
        display: flex;
        flex-direction: column;
    }

    &__title {
        margin: 0;
        font-weight: 500;
        font-size: 27px;
        color: #48587d;
    }

    &__description {
        margin-bottom: 0;
        margin-top: 5px;
        font-weight: 400;
        font-size: 16px;
    }

    &__content {
        margin-top: 32px;
        max-width: 840px;
    }
}
