.tabs {
  display: flex;
  justify-content: flex-start;
  gap: 25px;
  align-items: center;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;

  &__item {
    cursor: pointer;
    line-height: 150%;
    &_active {
      border-bottom: 2px solid #2493fb;
      cursor: auto;
      color: #2493fb;
      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 2px;
        background: #2493fb;
      }
    }
  }
}
