.wrapper {
    .group {
        &__date {
            box-sizing: border-box;
            height: 27px;
            background-color: #f8f8f8;
            padding: 6px 14px;
            font-size: 12px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            color: #48587d;
        }
    }
}
