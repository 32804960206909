@import '../../styles/mediaQueries.scss';

.template {
  height: 100vh;
}

.root {
  width: 100%;
  height: calc(100vh - 75px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  box-sizing: border-box;

  > .content {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: space-around;
    flex: 1 0 auto;

    > div {
      width: 50%;
    }

    .bgImage {
      margin: 0 auto;
      height: 270px;
      background: url('/assets/PeopleMobile.svg') no-repeat center bottom;
      background-size: contain;
      @media (max-width: 650px) {
        width: 100%;
        max-width: none;
      }
    }

  @media (max-width: 650px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      padding: 0 30px;
      > div {
        width: auto;

        &:first-child {
          width: 100%;
          flex-grow: 1;
        }
      }
    }
  }

  div {
    flex: 0 0 auto;

    h2 {
      font-weight: 500;
      font-size: 40px;
      line-height: 46px;
      color: #48587d;
      margin: 5px 20px;
    }

    h3 {
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      color: #777f95;
      padding-top: 20px;
    }

    @media (max-width: 650px) {
      h2 {
        font-size: 32px;
        line-height: 37px;
      }
      h3 {
        font-size: 14px;
        line-height: 16px;
        padding-top: 0;
      }
    }
  }

  .createBlock {
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    margin-top: 60px;
    box-sizing: border-box;
    @media (max-width: 650px) {
      margin-top: 30px;
      padding-right: 0;
    }

    > * {
      text-align: start;

      &:last-child {
        margin: 20px 0 10px;
        justify-content: flex-start;
        gap: 20px;
        @media (max-width: 650px) {
          flex-direction: column;
          justify-content: center;
          & > * {
            width: 100%;
            justify-content: center;
          }
        }

        > * {
          margin: 0;
        }
      }
    }

    button {
      flex-grow: 0;
      margin-bottom: 0;
    }
  }

  .joinConfBlock {
    box-sizing: border-box;
    width: 100%;
    flex: 0 0 auto;
    padding: 16px 13px 13px 28px;
    margin-top: 20px;
    background-color: #D7DDEC;
    z-index: 6;

    .joinContent {
      display: grid;
      box-sizing: border-box;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 8px;
      @media (max-width: 650px) {
        grid-template-columns: 1fr 1fr;
      }

      > * {
        margin-bottom: 0;
        @media (max-width: 650px) {
          grid-column: 1/3;
        }
      }

      > div:first-child {
        text-align: left;
        grid-column: 1/3;

        > * {
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          margin-bottom: 2px;

          &:last-child {
            font-size: 14px;
            font-weight: 400;
            line-height: 16.5px;
          }
        }
      }
    }
  }
}
