.page {
    background-color: #121213;
    height: 100vh;
}

.wrapper {
    // max-width: 1440px;
    margin: 0 auto;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error {
    font-size: 24px;
    color: #ffffff;
    font-weight: 500;
}

.button {
    display: block;
    width: 312px;
    height: 40px;
    margin: 10px auto 0;
}
