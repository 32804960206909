.dialog {
    max-width: 522px;
    width: 100%;
    padding: 25px;

    &__title {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #48587d;
    }

    &__body {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #777f95;
    }
}
