.form {
    &__submit {
        display: flex;
        padding: 8px 24px;
        margin-left: auto;
        margin-top: 25px;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 16px;
        background: #4176fa;
        border-radius: 4px;

        &SuccessMessage {
            color: #fff;
            background-color: #86daad;
        }
    }

    &__errorWrapper {
        max-width: 70%;
        margin: 0 auto;
        margin-bottom: 15px;
    }
}
