.list {
    position: relative;
    z-index: 17;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.8);
    border: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;

    &__column {
        flex-direction: column;
        height: auto;

        > div:not(:first-of-type) {
            margin-top: 10px;
        }
    }

    &__bottom {
        padding: 10px 0;
        &::before {
            content: '';
            position: absolute;
            width: 9px;
            height: 9px;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%) translateY(50%) rotate(45deg);
            background: rgba(0, 0, 0, 0.8);
            border: 1px solid rgba(255, 255, 255, 0.2);
            z-index: 1;
            border-left: none;
            border-top: none;
        }
    }

    &__top::before {
        content: '';
        position: absolute;
        width: 9px;
        height: 9px;
        top: 0;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        background: rgba(0, 0, 0, 0.8);
        border: 1px solid rgba(255, 255, 255, 0.2);
        z-index: 1;
        border-bottom: none;
        border-right: none;
    }
}
