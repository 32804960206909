.block {
    background: rgba(0, 0, 0, 0.85);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    border-radius: 8px;
    width: 394px;
    height: 352px;
}

.text {
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    width: 70%;
    margin: 0 auto;
}