.license {
    &__info {
        display: flex;
        flex-direction: column;
        gap: 30px;

        &Text {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: #363a53;
        }

        &Label {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: #9095b2;
        }

        &Block {
            display: grid;
            grid-template-columns: 120px max-content;
            align-items: center;
            justify-items: flex-start;
            gap: 55px;
        }
    }
    &__status {
        padding: 4px 10px;
        color: #fff;
        font-weight: 500;
        font-size: 14px;
        border-radius: 14px;
        background: #86daad;

        &_inactive {
            background: #ebac9e;
        }
    }
}
