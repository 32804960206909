.select {
    position: relative;
    width: auto;
    &__value {
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 10px 20px;
        font-weight: 400;
        font-size: 16px;
        background: #ffffff;
        border: 1px solid #cdd2e4;
        border-radius: 25px;
        box-sizing: border-box;
        transition: border 0.2s;

        &Arrow {
            margin-left: auto;
            transform: rotate(-90deg);
            transition: transform 0.2s;

            * {
                fill: #9095b2;
            }

            &_active {
                transform: rotate(90deg);
                * {
                    fill: #4176fa;
                }
            }
        }

        img {
            max-width: 20px;
            margin-right: 10px;
        }

        &_active {
            border-color: #4176fa;
        }
    }
    &__options {
        position: absolute;
        top: 100%;
        left: 50%;
        display: grid;
        grid-template-columns: 1fr;
        place-content: center;
        width: auto;
        min-width: 100%;
        transform: translate(-50%, 5px);
        border-radius: 25px;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
        background: #ffffff;
        box-sizing: border-box;
        overflow: hidden;
        z-index: 10;
    }
    &__option {
        display: flex;
        align-items: center;
        padding: 15px 20px;
        font-weight: 400;
        font-size: 16px;
        box-sizing: border-box;
        cursor: pointer;
        transition: background-color 0.2s;

        &ActiveImg {
            margin-left: auto;
        }

        &:hover {
            background-color: #f0f1f4;
        }

        img {
            width: 15px;
            margin-right: 10px;
        }
    }
}
