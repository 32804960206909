.footer {
    display: flex;
    align-items: flex-start;
    height: 73px;
    box-sizing: border-box;
    border-top: 1px solid #e9ecf4;
    padding: 8px 19px 0;

    & > .buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;

        button {
            background-color: transparent;
        }

        & .name {
            color: #48587d;
            font-size: 10px;
        }
    }
}
