.container {
    width: 230px;
    margin-left: auto;
    user-select: none;
    cursor: pointer;

    [data-select-options] {
        border-radius: 15px;
    }

    [data-select-item] {
        padding: 10px 15px;
        
        &:not(:last-child) {
            border-bottom: 1px solid #e6e8f0;
        }
        &:hover {
            background: #f8f9ff;
        }
    }
}
