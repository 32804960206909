.settings {
    color: #ffffff;
    overflow: visible;
}

.itemSettings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    &__text {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;

        & > img {
            padding-right: 12px;
        }
    }
}

.videoSettings {
    max-width: 320px;
    width: 100%;
    height: 180px;
    margin: 0 auto 47px auto; 
    position: relative;
    text-align: center;

    & video {
        height: 100%;
        width: 100%;
        margin-bottom: -41px;
        object-fit: cover;
    }
}

.cameraSelect {
    margin-top: auto;
    position: absolute;
    width: 100%; 

    & .deviceItems {
        border-radius: 0px;
        background-color: rgba(0, 0, 0, 0.3);
        text-align: left;
    }
}

.micSettings {
    & .deviceMain {
        font-size: 16px;
    }
}
