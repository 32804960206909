@keyframes slideFromLeft {
    from {
        transform: translate(-150%, 0);
    }
    to {
        transform: translate(-50%, 0);
    }
}

@keyframes slideToLeft {
    from {
        transform: translate(-50%, 0);
    }
    to {
        transform: translate(-150%, 0);
    }
}

.conferenceInfoFrame {
    position: absolute;
    left: 50%;
    top: 3%;
    width: 100%;
    max-width: 320px;
    padding: 20px;
    color: #fff;
    background: #121213e5;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 5;
    // animation: slideFromLeft 0.4s linear;
    // animation-fill-mode: forwards;

    &_show {
        animation: slideFromLeft 0.4s linear;
        animation-fill-mode: forwards;
    }

    &_hide {
        animation: slideToLeft 0.4s linear;
        animation-fill-mode: forwards;
    }

    &_tablet {
        max-width: 420px;
        padding: 10px 20px;
    }

    &__list {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 20px;
    }

    &__item {
        display: grid;
        grid-template-columns: 100px 1fr;
        column-gap: 20px;
        font-size: 14px;

        > *:first-child {
            color: #acacac;
        }

        > *:last-child {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            overflow: hidden;
        }
    }

    &__linkBtn {
        margin-top: 10px;
        padding: 0;
        color: #2493fb;
        font-size: 14px;
        line-height: 16px;
        background: transparent;
        border: none;
        cursor: pointer;
    }

    &__linkText {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__registeredOnly {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 37px;

        & > *:last-child {
            margin-top: 15px;
        }
    }
}

.controls {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px 0;
    margin-top: 20px;

    &_tablet {
        button {
            height: 35px;
        }
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;

        > *:first-child {
            margin-right: 10px;
            width: 16px;
            height: 16px;
        }

        &_recordProcessing {
            pointer-events: none;
        }
    }
}

.record {
    &_active path {
        fill: #ff123d;
    }
}
