@mixin mobilePopupContainer {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #ffffff 49.48%, #e8eaff 100%);
    text-align: center;
}

@mixin scrollbar {
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #b7bbc0;
        outline: none;
        border-radius: 8px;
    }
}
