.icons {
  display: flex;
  justify-items: center;
  align-items: center;
}

.iconMicrophone {
  display: block;
  height: 20px;
}

.iconCamera {
  display: block;
  width: 28px;
}
