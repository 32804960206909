.root {
    background-color: #181818;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 0 11px;
    width: 100%;
    position: absolute;
    top: 76px;
    z-index: 10;

    > div {
        padding: 6px 0;
        color: white;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        text-align: left;
        text-overflow: ellipsis;
    }
}
