.controls {
    display: grid;
    grid-template-columns: 100px 120px;
    gap: 30px;

    &Button {
        font-size: 16px;

        &_delete {
            color: #dd4e2e;
            border: 1px solid transparent;
            transition: border-color 0.2s;
            &:hover {
                border-color: #d7d8e1;
            }
        }

        &_save {
            background-color: #4176fa;
            transition: background-color 0.2s;
            &:hover {
                background-color: #3d70f0;
            }

            &:disabled:hover {
                background-color: #4176fa;
            }
        }
    }
}
