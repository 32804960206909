.menu {
    display: flex;
    align-items: center;
    gap: 34px;

    & > a, & > button  {
        color: #2493fb;
        font-size: 14px;
        outline: none;
        text-decoration: none;
        font-weight: 500;
        cursor: pointer;
    }
}
