.link {
    margin-bottom: 15px;
    color: #2493fb;
    display: flex;
    align-items: center;
    cursor: pointer;

    & > img {
        margin-right: 7px;
    }
}