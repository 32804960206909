.chooseButton {
    margin-top: 16px;
    display: inline-flex;
    gap: 11px;
    padding: 8px 16px;
    background: #fff;
    border: 1px solid #e9ebf5;
    border-radius: 4px;
    color: #48587D;
    cursor: pointer;
    img {
        filter: grayscale(1);
    }
}
.dropDown,
.datesPopup {
    position: absolute;
    background: #fff;
    padding: 16px 18px;
    border: 1px solid #e9ebf5;
    box-shadow: 0px 3px 10px #dbdbdb;
    border-radius: 4px;
}
.popup {
    position: absolute;
    margin-top: 4px;
    z-index: 999;
    .dropDown {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 12px;
            li {
                margin: 0;
                padding: 0;
                font-size: 16px;
                line-height: 150%;
                white-space: nowrap;
            }
            label {
                cursor: pointer;
            }
            .chooseDates {
                display: flex;
                align-items: center;
                gap: 8px;
                border-top: 1px solid #e9ebf5;
                padding: 12px 0 0 0;
                .icon {
                    background: #2493fb;
                    width: 16px;
                    height: 16px;
                    color: #fff;
                    text-align: center;
                    border-radius: 50%;
                    line-height: 16px;
                }
            }
            .sel,
            input:checked ~ label {
                font-weight: 500;
            }
        }
    }
    .datesPopup {
        left: 100%;
        top: 0;
        margin-left: 4px;
        div[class='react-datepicker-popper'] {
            position: static !important;
            transform: none !important;
            padding-top: 0 !important;
        }
        div[class='react-datepicker'] {
            box-shadow: none;
            & > div:nth-child(2):after {
                display: none;
            }
        }
        div[class='react-datepicker__month-container'] {
            padding: 0;
        }
        div[class='react-datepicker__tab-loop'] {
            overflow: hidden;
            display: flex;
            justify-content: center;
        }
        .popupDateForm {
            display: flex;
            gap: 24px;
            flex-direction: column;
            margin-top: 20px;
            .fields {
                display: flex;
                align-items: center;
                gap: 8px;
                input[type='text'] {
                    padding: 8px 22px;
                    border: 1px solid #e9ebf5;
                    border-radius: 4px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    width: 126px;
                    box-sizing: border-box;
                }
            }
            .buttons {
                display: flex;
                gap: 8px;
                button {
                    text-align: center;
                    padding: 8px 24px;
                    background: #ffffff;
                    color: #2493fb;
                    border: 1px solid #2493fb;
                    border-radius: 4px;
                    min-width: 138px;
                    box-sizing: border-box;
                }
                button[type='submit'] {
                    background: #2493fb;
                    color: #fff;
                }
            }
        }
    }
}
