
.container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
.input {
    border-radius: 8px;
    font-size: 14px;
    color: #48587d;
    width: max-content;
    height: 37px;
    padding: 10px 16px;
    border: 1px solid #48587d;
}