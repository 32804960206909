@import '../../../styles/mediaQueries.scss';

.root {
    .controls {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(50px, 103px));
        grid-auto-rows: 37px;
        place-content: center;
        padding: 0 12px;
        margin: 10px 0 15px;
    }

    .additionalOptions {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        margin: 0 auto;
        font-size: 10px;
        color: #a4adc0;
        &__img {
            transform: rotate(90deg) scale(1.5);
            margin-bottom: auto;
            circle {
                fill: #a4adc0;
            }
        }
    }

    .submitBtnWrapper {
        margin: 0 12px;
    }

}
