.shareDialog {
    display: flex;
    max-width: 515px;
    flex-direction: column;
    gap: 24px;
    padding: 25px 32px 32px;
    font-size: 16px;
    color: #646E8B;
    background: #FFFFFF;

    &__header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-size: 21px;
        font-weight: 500;
    }
}