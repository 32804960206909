.cross {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: relative;

    &:before,
    &:after {
        position: absolute;
        content: ' ';
        height: 13px;
        width: 2px;
        background-color: #48587d;
        left: 9px;
        top: 3px;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
}

.white {
    &:before,
    &:after {
        background: #ffffff;
    }
}

.gray {
    &:before,
    &:after {
        background: #adb9cf;
    }
}
