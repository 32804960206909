.container {
    flex-wrap: nowrap;
}

.link {
    justify-content: center;
    gap: 10px;

    img {
        border-radius: 6px;
        cursor: pointer;
    }

    &__fileInfo {
        display: flex;
        flex-direction: column;
        max-width: 75%;
    }

    &__fileMetaInfo {
        margin-top: 5px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #9d9d9d;

        &_white {
            color: #ffffff;

            .link__fileSize:before {
                background: #ffffff;
            }
        }
    }

    &__fileFormat {
        text-transform: uppercase;
    }

    &__fileSize {
        margin-left: 12px;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            width: 3px;
            height: 3px;
            top: 50%;
            left: -6px;
            transform: translate(0, -50%);
            border-radius: 50%;
            background: #9d9d9d
        }
    }

    &__fileName {
        font-weight: 500;
        font-size: 13px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
