.form {
    position: absolute;
    background-color: #ffffff;
    width: 100%;
    bottom: 0;
    left: 0;
    border-radius: 12px 12px 0px 0px;

    .header {
        display: flex;
        justify-content: space-between;
        padding: 14px 14px 15px;
        font-size: 18px;
        font-weight: 500;
        color: #48587d;
    }

    .text {
        color: #48587d;
        padding: 0 14px 11px;
    }

    .block {
        margin-top: 18px;

        & input {
            background-color: #f8f9fc;
        }
    }

    .submit {
        padding: 0 14px;
        margin: 27px 0;
        font-weight: 500;
        font-size: 14px;
    }
}
