$white: #ffffff;
@mixin login_base {
    color: #2493fb;
    background-color: $white;
    border: 1px solid #2493fb;
}
@mixin secondaryAction_base {
    padding: 8px 24px;
    color: #48587d;
    background-color: $white;
    border: 1px solid #48587d;
}
@mixin secondaryActionNew_base {
    padding: 8px 24px;
    color: #2493fb;
    background-color: $white;
    border-radius: 4px;
    border: 1px solid #2493fb;
    transition: 0.2s;

    &:hover {
        color: $white;
        background-color: #2493fb;
    }
    &:active {
        color: #2493fb;
        border: 1px solid #2493fb;
    }
    &[disabled]:hover {
        color: #2493fb;
        background-color: $white;
    }
}
@mixin secondaryActionNew_red {
    $red: #f55b76;
    color: $red;
    border-color: $red;
    &:hover {
        color: $white;
        background-color: $red;
    }
    &:active {
        color: $red;
        border: 1px solid $red;
    }
    &[disabled]:hover {
        color: $red;
        border-color: $red;
    }
}

@mixin primaryAction_base {
    padding: 8px 24px;
    color: $white;
    background-color: #2493fb;
    border: none;
}
@mixin primaryActionNew_base {
    padding: 8px 24px;
    color: $white;
    background-color: #2493fb;
    border-radius: 4px;
    border: none;
    &:hover {
        background-color: #1c87ea;
    }
    &:active {
        background: #1478d3;
    }
    &[disabled]:hover {
        background-color: #2493fb;
    }
}
@mixin primaryActionNew_red {
    $red:#f55b76;
    $redHover:#f56c85;
    $redActive:#ff627f;
    background-color: $red;
    &:hover {
        background-color: $redHover;
    }
    &:active {
        background: $redActive;
    }
    &[disabled]:hover {
        background-color: $red;
    }
}
@mixin mobilePrimaryAction_base {
    font-size: 14px;
    border-radius: 12px;
    color: $white;
    background-color: #2493fb;
    border: none;
    height: 48px;
}
@mixin mobileSecondaryAction_base {
    color: #2493fb;
    background-color: $white;
    border: 0;
    font-size: 14px;
    border-radius: 12px;
    height: 48px;
}
.button {
    user-select: none;
    border-radius: 30px;
    box-sizing: border-box;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: 43px;
    text-align: center;
    width: 100%;
    transition: background 0.2s, opacity 0.2s;

    &[disabled] {
        cursor: context-menu;
        &[type='submit'] {
            opacity: 0.5;
        }
    }

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }

    &_text {
        width: auto;
        height: auto;
        background: transparent;
        border: none;
        border-radius: unset;
    }

    &_common {
        width: auto;
        height: auto;
        background: transparent;
    }

    &_login {
        @include login_base;
        path {
            fill: #2493fb;
            transition: fill 0.3s;
        }
        &:hover {
            background-color: #1c87ea;
            color: $white;
            path {
                fill: $white;
            }
        }
        &:active {
            background-color: #1478d3;
            color: $white;
        }
        &[disabled]:hover {
            @include login_base;
        }
    }

    &_secondaryAction {
        @include secondaryAction_base;
        &:hover {
            color: #2493fb;
            border: 1px solid #2493fb;
        }
        &:active {
            color: #2493fb;
            border: 1px solid #2493fb;
        }
        &[disabled]:hover {
            @include secondaryAction_base;
        }
    }

    &_secondaryActionNew {
        @include secondaryActionNew_base;

        &-red {
            @include secondaryActionNew_red;
        }
    }

    &_primaryAction {
        @include primaryAction_base;
        &:hover {
            background-color: #1c87ea;
        }
        &:active {
            background: #1478d3;
        }
        &[disabled]:hover {
            @include primaryAction_base;
        }
    }

    &_primaryActionNew {
        @include primaryActionNew_base;
        &-red {
            @include primaryActionNew_red;
        }
    }

    &_loading {
        background-color: #b9ddff;
        &:active {
            background-color: #b9ddff;
        }
        &:hover {
            background-color: #b9ddff;
        }
    }

    &_send {
        width: 20px;
        height: 20px;
        background: url('../../../assets/PaperFlyGray.svg') center center no-repeat;
        border-radius: 0;
        border: none;

        path {
            fill: #adb9cf;
        }
    }

    &_kebab {
        width: 4px;
        height: 18px;
        background: url('../../../assets/Kebab.svg') center center no-repeat;
        border: none;
        border-radius: 0;
        transition: opacity 0.3s;
    }

    &_mobilePrimaryAction {
        @include mobilePrimaryAction_base;
        &:hover {
            background-color: #1c87ea;
        }

        &:active {
            background: #1478d3;
        }
        &[disabled]:hover {
            @include mobilePrimaryAction_base;
        }
    }

    &_mobileSecondaryAction {
        @include mobileSecondaryAction_base;
        &:hover {
            color: #2493fb;
            border: 1px solid #2493fb;
        }

        &:active {
            color: #2493fb;
            border: 1px solid #2493fb;
        }
        &[disabled]:hover {
            @include mobileSecondaryAction_base;
        }
    }

    &_mobileLink {
        background-color: transparent;
        border: 0;
        color: #2493fb;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        height: 48px;
        line-height: 140%;
    }

    &_red {
        background: #d05353;
        border-radius: 4px;
        border: 0;
        color: $white;
        font-size: 14px;
        padding: 3px 8px;
    }

    &_mobileSemiTransparent {
        background: #ffffff14;
        border-radius: 12px;
        border: none;
        color: $white;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        height: 48px;
        line-height: 140%;
        &:hover {
            background: #ffffff20;
        }

        &:active {
            background: #ffffff20;
        }
    }

    &_transparent {
        width: auto;
        height: auto;
        border: 0;
        background-color: transparent;
    }

    &_lobbyAllowMobile {
        background: url('../../../assets/LobbyAllowMobile.svg') 50% 40% no-repeat;
        background-color: rgba(127, 184, 34, 0.05);
        border-radius: 0;
        border-top: 0;
        border-bottom: 0;
        border-left: 1px solid black;
        border-right: 0;
        color: #7fb822;
        font-size: 10px;
        font-style: normal;
        font-weight: normal;
        height: 48px;
        line-height: 22px;
        padding-top: 20px;
        text-align: center;
        width: 60px;
    }

    &_lobbyDenyMobile {
        background: url('../../../assets/LobbyDenyMobile.svg') 50% 40% no-repeat;
        background-color: rgba(204, 57, 49, 0.05);
        border-radius: 0;
        border-top: 0;
        border-bottom: 0;
        border-left: 1px solid black;
        border-right: 0;
        color: #d03232;
        font-size: 10px;
        font-style: normal;
        font-weight: normal;
        height: 48px;
        line-height: 22px;
        padding-top: 20px;
        width: 60px;
    }

    &_deleteMobile {
        background: #ffe5df;
        border-radius: 12px;
        height: 48px;
        width: 100%;
        color: #e36767;
        padding: 14px 0;
        font-weight: 500;
        font-size: 14px;
    }

    &_exitMobile {
        background: #e36767;
        border-radius: 12px;
        height: 48px;
        width: 100%;
        color: #ffffff;
        padding: 14px 0;
        font-weight: 500;
        font-size: 14px;
    }

    &_dot {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: none;
        background: #3f3f3f;
    }
}
