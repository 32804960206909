.root {
    width: 100%;
    height: 100%;
    background-color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    font-size: 12px;

    .wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;

        & > div:last-of-type {
            margin-top: 10px;
        }
    }
}
