.notifications {
    width: 327px;
    position: absolute;
    z-index: 10;
    overflow: hidden;
    & > div {
        color: #ffffff;
        font-size: 14px;
        margin-bottom: 10px;
    }

    & .header {
        padding: 11px 0px 9px 13px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        color: #ffffff;
        display: flex;
        align-items: center;
        font-weight: 500;
        justify-content: center;
    }

    & .footer {
        flex-grow: 1;
        height: 55px;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        & .button {
            cursor: pointer;
            flex-basis: 50%;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            padding: 10px 0;

            &:first-child {
                border-right: 1px solid rgba(255, 255, 255, 0.1);
            }
        }
    }

    & .notification {
        display: flex;
        flex-direction: column;
        background: rgba(18, 18, 19, 0.85);
        border: 1px solid rgba(255, 255, 255, 0.1);
        box-sizing: border-box;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.4);
        border-radius: 8px;
        width: 327px;
    }
}
