.noScroll {
    height: 100vh;
    overflow: hidden;
}

.wrapper {
    display: flex;
    min-height: calc(100vh - 71px);
}

.sidebar {
    display: flex;
    flex-direction: column;
    padding: 12px;
    font-size: 14px;
    background-color: #f6f7fc;
    color: #48587d;
    flex-shrink: 0;
    box-sizing: border-box;
    width: 200px;
    .header {
        margin-bottom: 12px;
    }
}
.menu {
    display: flex;
    flex-direction: column;
    gap: 4px;
    &Item {
        padding: 8px 18px;
        cursor: pointer;
        border-radius: 8px;
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 12px;
        color: #48587d;
        &:hover {
            color: #2493fb;
        }
        &.selected {
            background-color: #2493fb;
            color: #ffffff;
        }
        &Text {
            display: block;
            flex-grow: 1;
        }
        &::before {
            content: '';
            display: block;
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            background-position: left center;
        }
        &:hover::before {
            filter: invert(62%) sepia(72%) saturate(5447%) hue-rotate(190deg) brightness(101%)
                contrast(97%);
        }
        &.selected::before,
        &.selected:hover::before {
            filter: brightness(0) invert(1);
        }
        &_profile::before {
            content: '';
            display: block;
            background-image: url('./images/profile.svg');
        }
        &_conferences::before {
            content: '';
            display: block;
            background-image: url('./images/conferences.svg');
        }
        &_records::before {
            content: '';
            display: block;
            background-image: url('./images/records.svg');
        }
        &_settings::before {
            content: '';
            display: block;
            background-image: url('./images/settings.svg');
        }
        &_exit::before {
            content: '';
            display: block;
            background-image: url('./images/exit.svg');
        }
    }
}

.version {
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 11px;
    color: rgba(200, 200, 200, 0.8);
    user-select: text;
    margin-top: auto;
    margin-bottom: 20px;
}

@media (max-width: 1200px) {
    .sidebar {
        width: 64px;
        .menuItem {
            padding: 8px;
            &Text {
                display: none;
            }
            &::before {
                background-position: center;
            }
        }
        .header {
            visibility: hidden;
        }
        .version {
            visibility: hidden;
        }
    }
}
.content {
    flex-grow: 1;
    padding: 43px 24px;
    min-height: calc(100vh - 247px);
    height: 93vh;
    overflow-y: auto;
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: #c4c4c4;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c4c4c4;
        outline: none;
        border-radius: 40px;
    }

    &:focus-visible {
        outline: none;
    }
}


.loader {
    margin: auto;
}