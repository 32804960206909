.container {
    &__body {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 7px;
        max-height: 500px;
        overflow-y: auto;

        &Loader {
            & > div {
                position: relative;
                left: unset;
                top: unset;
            }
        }

        &_mobile {
            justify-content: center;
        }

        &_fixed {
            height: 100px;
            justify-content: center;
            align-items: center;
        }
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;
    }

    &__submit {
        padding: 12px 45px;
        color: #ffffff;
        background: #2c5cba;
        border-radius: 6px;
    }
}

.dialog {
    max-width: 600px;
    padding: 15px 20px;
    margin: 15px;

    &__header {
        padding-right: 15px;
        font-weight: 500;
        font-size: 14px;
        color: #253e71;
    }
}
