.datePlaceholder {
    opacity: 0.5;
}

.cross {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.positionWrapper {
    position: relative;
    width: min-content;
    margin-top: 20px;
}

.datesSeparator {
    margin: 0 20px;
}

.wrapper {
    display: inline-flex;
    align-items: center;
    border: 1px solid #bac0ce;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 38px 12px 0;
    cursor: pointer;

    &:hover {
        border: 1px solid #2493fb;
    }

    & img {
        cursor: pointer;
        &:last-of-type {
            margin-left: 15px;
        }
        &:first-of-type {
            margin-right: 15px;
        }
    }

    & span {
        font-size: 16px;
        font-weight: 400;
        color: #48587d;
    }

    & input {
        color: #48587d;
        font-size: 14px;
        border: none;
        outline: none;
        width: 72px;
        cursor: pointer;
    }
}

.error {
    border: 1px solid #ff123d;
    &:hover {
        border: 1px solid #ff123d;
    }
}
