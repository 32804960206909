@import '../../styles/animations.scss';

.form {
    max-width: 660px;
    &__notes {
        font-weight: normal;
        font-size: 12px;
        padding-left: 24px;
        margin-top: -8px;
        color: #777f95;
    }

    & .pinCheckbox {
        margin-right: 26px;
    }

    .submitErrorWrapper {
        max-width: 350px;
        margin: 0 auto 15px;
    }

    &__desktopField {
        display: flex;
        flex-grow: 1;
        @media (max-width: 768px) {
            display: none;
        }
    }
}

.inputStyle {
    border-radius: 8px;
    font-size: 14px;
    color: #48587d;
    width: 100%;
    height: 37px;
    padding: 10px 16px;
    border: 1px solid #48587d;

    &__statusText {
        position: absolute;
    }

    &_time {
        width: 70px;
    }

    &_date {
        width: 105px;
    }

    &_duration {
        width: 85px;
    }
}

.alignStart {
    align-items: flex-start;
}

.text {
    align-self: center;
    font-weight: 500;
    font-size: 14px;
    flex-basis: 132px;
}

.radioButton {
    margin-right: 27px;
}

.select {
    [data-js-select-header] {
        max-width: 300px;
    }

    &_permission {
        [data-js-select-header] {
            max-width: 200px;
        }
    }
}

.column > div:not(:first-of-type) {
    flex-direction: column;
    align-items: flex-start;
}

.timeZone {
    width: 300px;
}

.button {
    font-size: 16px;
    font-weight: 500;
    height: 43px;

    &__save {
        font-size: 16px;
        font-weight: 500;
        width: 218px;
    }

    &__cancel {
        width: 186px;
        border: 1px solid #48587d;
        color: #48587d;

        &_disabled {
            border-color: #bac0ce;
            color: #bac0ce;
            cursor: default;

            &:hover {
                background-color: #ffffff;
            }
        }
    }
}

.noMarginBottom {
    margin-bottom: 0;
}

.marginBorder {
    border: 1px solid transparent;
    margin-bottom: 18px;
}
