.wrapper {
    position: relative;
    button {
        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }
    & > img {
        position: absolute;
        right: 0;
        bottom: 15px;
    }

    &_disabled {
        & > button {
            color: #555555;
        }
    }

    &_on {
        button {
            color: #4dbc5e;
        }
    }

    .hoverWrapper {
        position: absolute;
        bottom: calc(100% + 10px);
        left: 50%;
        width: inherit;
        visibility: hidden;
        color: #ffffff;
        font-size: 12px;
        text-align: center;
        opacity: 0;
        transition: opacity 0.2s;
        transform: translateX(-50%);
        z-index: 10;
    }

    &:hover {
        .hoverWrapper {
            visibility: visible;
            opacity: 1;
        }
    }
}

.imgWrapper {
    position: relative;
    &_disabled {
        &::after {
            content: '';
            position: absolute;
            width: 10px;
            height: 12px;
            right: -10px;
            bottom: 1px;
            background: url('../../../assets/LockGray.svg') center center no-repeat;
        }
        path {
            fill: #555555;
        }
    }
    &_on {
        path {
            fill: #4dbc5e;
        }
    }
}

.screenImg {
    path {
        transition: fill 0.3s;
    }
}
