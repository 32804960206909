.img {
    max-width: 100%;
    max-height: 100%;
    &_hidden {
        visibility: hidden;
        transform: scale(0);
    }
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
