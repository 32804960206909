.conferenceParticipantsList {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-basis: 290px;
    flex-grow: 1;
    flex-shrink: 0;
    background: #333333;
    height: 100%;
    max-width: 290px;

    &__header {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 22px 16px;
        color: #adb9cf;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: #ffffff;
            opacity: 0.06;
        }

        & .cross {
            position: relative;
        }
    }

    &__body {
        position: relative;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        color: #adb9cf;
        background: #333333;
    }

    &__counter {
        margin-left: 10px;
        color: #45d05b;
        font-weight: 500;
        font-size: 14px;
        font-family: Seravek;
    }
}
