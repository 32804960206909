.body {
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-template-areas: 'space days' '. result';
    grid-auto-rows: auto;
    margin-top: 32px;
    max-width: 430px;
}

.footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px;
}

.actionButton {
    width: min-content;
    height: initial;
    padding: 10px 22px;

    &:disabled {
        background-color: #b9ddff;
        cursor: default;

        &:hover {
            background: #b9ddff;
        }
    }

    &:first-of-type {
        margin-right: 10px;
    }
}

.img {
    margin-top: 10px;
    grid-area: space;
}

.errorContainer {
    margin-bottom: 14px;
    font-family: 'Roboto', sans-serif;
}

.resultTextInfo {
    grid-area: result;
}
