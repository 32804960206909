.checkbox {
    & > label {
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;

        &::before {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            content: '';
            height: 14px;
            width: 14px;
            border: 1px solid #48587d;
            border-radius: 2px;
            background: #ffffff;
            box-sizing: border-box;
        }
    }

    & > input {
        display: none;
        &:checked ~ label::before {
            content: url('../../../assets/CheckboxActive.svg');
            background: #2493fb;
            border: none;
        }
    }

    &_disabled {
        & > label {
            cursor: default;

            &::before {
                background: #eef0f5;
                border: 1px solid #bac0ce;
            }
        }
        & > input {
            display: none;
            &:checked ~ label::before {
                content: url('../../../assets/CheckboxInactive.svg');
                background: #bfc8cf;
                border: none;
            }
        }
    }
}
