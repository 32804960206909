@import '../../../styles/animations.scss';

.style {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    font-size: 14px;
    padding: 8px;
    box-sizing: border-box;

    &_notify_green {
        @include fadeInAnimation(0.2s);
        background: rgba(144, 237, 70, 0.3);
        color: #35772a;

        &::before {
            content: url('../../../assets/CopyGreen.svg');
            margin-right: 10px;
        }
    }

    &_notify {
        @include fadeInAnimation(0.2s);
        background: rgba(144, 237, 70, 0.3);
        color: #fff;

        &::before {
            content: url('../../../assets/Copy.svg');
            margin-right: 10px;
        }
    }

    &_success {
        @include fadeInAnimation(0.2s);
        background: #ddf9de;
        color: #42703e;

        &::before {
            content: url('../../../assets/CheckGreen.svg');
            margin-right: 10px;
        }
    }

    &_success_email {
        @include fadeInAnimation(0.2s);
        background: rgba(144, 237, 70, 0.3);
        color: #35772a;

        &::before {
            content: url('../../../assets/EmailNotify.svg');
            margin-right: 10px;
        }
    }

    &_error {
        background-color: #ffeded;
        color: #b7384e;
    }
}

.itemFadeOut {
    @include fadeOutAnimation(0.2s);
}

