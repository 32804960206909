.owner {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    .info {
        margin-left: 12px;
        & > div:first-of-type {
            margin-bottom: 5px;
        }
    }

    .fullName {
        font-weight: 500;
    }
}
