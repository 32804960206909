.conferenceSettingsFooter {
    display: flex;
    gap: 40px;
    width: auto;
    justify-content: flex-end;
    margin: auto;
    margin-right: 0;

    @media (max-width: 768px) {
        justify-content: center;
    }

    button {
        width: 210px;
    }
}
