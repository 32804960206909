.form {
    &__searchFieldWrapper {
        padding-top: 8px;
        padding-bottom: 8px;
        max-width: 355px;
        height: max-content;
        border: none;
        background-color: var(--ghost-white);
    }
    &__searchField {
        font-size: 16px;
        line-height: 19px;
        background-color: var(--ghost-white);
    }
}
