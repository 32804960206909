.emailList {
    width: 100%;
    min-height: 100px;
    margin-top: 8px;
    border: 1px solid #e9ebf5;
    border-radius: 4px;
}

.button {
    display: block;
    max-width: 250px;
    margin: 24px 0 0 auto;

    @mixin disabled {
        color: #aab1c6;
        background: #e9ebf5;
        opacity: 1;
    }

    &[disabled] {
        @include disabled;
        &:hover {
            @include disabled;
        }
    }
}
