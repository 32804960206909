.users {
    width: 100%;
    display: flex;

    @media (max-width: 992px) {
        grid-area: invited;
    }

    &_desktop {
        max-width: 350px;
        align-items: center;
        justify-content: flex-end;
        box-sizing: border-box;

        @media (max-width: 992px) {
            max-width: 100%;
            margin-top: 10px;
            padding-right: 20px;
        }

        @media (min-width: 765px) and (max-width: 920px) {
            align-items: flex-start;
            margin-top: 0;
        }

        @media (max-width: 765px){
            justify-content: flex-start;
        }
    }

    & div {
        font-size: 10px;
    }

    &__remainder {
        color: #48587d;
        background-color: #e9ecf4;
    }
}
