@import '../../ConferencesList.module.scss';

.conference {
    display: grid;
    grid-template-columns: 180px 1fr 200px 216px 24px;
    @include conference;
    gap: 10px;
    min-width: 300px;

    @media (max-width: 992px) {
        grid-template-columns: 135px repeat(2, 1fr) 100px 24px;
        gap: 10px;
        grid-template-areas: 'time info invited mainBtn kebabBtn';
    }

    @media (max-width: 920px) {
        grid-template-columns: 135px repeat(2, 1fr) 24px;
        gap: 10px;
        grid-template-areas:
            'time info invited kebabBtn'
            'time info mainBtn kebabBtn';
    }

    @media (max-width: 765px) {
        grid-template-columns: 135px repeat(2, 1fr) 24px;
        gap: 10px;
        grid-template-areas:
            'time time time time'
            'info info mainBtn kebabBtn'
            'invited invited mainBtn kebabBtn';
    }

    &_started {
        background: #f6fcff;
        border: 1px solid #cbe0ff;
    }
}
