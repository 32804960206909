.container {
    button:disabled {
        cursor: default;
        background-color: #b9ddff;

        &:hover {
            background: #b9ddff;
        }
    }
}

.body {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 'space' 'days' 'result';
    grid-auto-rows: auto;
    margin-top: 24px;
    padding: 0 14px;
}

.footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 24px 0 45px;
    margin-top: 24px;
    margin-bottom: 45px;
    padding: 0 14px;
}

.img {
    margin-bottom: 30px;
    grid-area: space;
}

.errorContainer {
    margin-bottom: 14px;
    font-family: 'Roboto', sans-serif;
}

.resultTextInfo {
    grid-area: result;
}

.days_mobile {
    justify-content: space-between;

    & > label {
        flex-basis: 11vw;
        flex-shrink: 1;
    }
}
