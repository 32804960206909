.container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    
    img {
        max-width: 500px;
        object-fit: cover;
        margin: auto;
    }
}

.button {
    padding: 10px 0;
    border-radius: 5px;
    transition: opacity .2s;

    &[disabled] {
        opacity: .6;
        cursor: default;
    }
}

