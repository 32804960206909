@import '../../../styles/animations.scss';

.inputWithListBlock {
  position: relative;
}

.list {
  display: flex;
  position: absolute;
  top: calc(100% + 5px);
  flex-direction: column;
  width: 70px;
  height: auto;
  max-height: 167px;
  min-height: 40px;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid #e2e7ef;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 12px rgba(94, 110, 169, 0.4);
  @include fadeInAnimation(0.5s);
  z-index: 50;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b7bbc0;
    outline: none;
    border-radius: 8px;
  }
}

.listItem {
  display: flex;
  align-items: flex-start;
  padding-top: 6px;
  padding-left: 7px;
  cursor: pointer;

  &:hover {
    background: #eaf5ff;

    .user__info::after {
      background: #eaf5ff;
    }
  }
}
