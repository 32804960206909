@import '../../../../../styles/animations.scss';

.processing {
    display: flex;
    align-items: center;
}

.spinner {
    margin-right: 6px;
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: transparent;

    & > div {
        position: relative;
        width: 13px;
        height: 13px;
        border-right: 1px solid #777f95;
        border-bottom: 1px solid #777f95;
        border-left: 1px solid #777f95;
        border-top: 1px solid transparent;
        border-radius: 50%;
        transform: rotate(45deg);
        @include spinAnimation;
    }
}