.label {
    display: inline-block;
    padding: 6px 11px;
    background: #242424;
    border-radius: 8px;
    position: relative;

    & > .rectangle {
        position: absolute;
        width: 9px;
        height: 9px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%) translateY(50%) rotate(45deg);
        background: #242424;
    }

    &_blue, &_blue.label_username {
        background: #2493fb;
        & > .rectangle {
            background: #2493fb;
        }
    }

    &_username {
        background: #121213;
        border: 1px solid #2F2F2F;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.45));

        & > .rectangle {
            top: -11px;
            background: #121213;
            border-left: 1px solid #2F2F2F;
            border-top: 1px solid #2F2F2F;
        }
    }
}
