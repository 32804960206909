.conferenceSettings {
    margin: auto;
     
    &__main {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        margin: auto;
        max-width: 830px;
        min-height: 447px;
        background-color: rgba(0, 0, 0, 0.85);
        border: 1px solid rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(20px);
        border-radius: 8px;
        position: relative;
    }

    &__notifications {
        display: flex;
        justify-content: center;
        margin: 10px auto;
    }
}

.timerWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    & > span:last-child {
        margin-top: 10px;
        font-size: 24px;
    }
}

.settings,
.members {
    flex-basis: 50%;
    flex-shrink: 0;
    flex-grow: 1;
    height: 100%;
    padding: 28px 30px;
    box-sizing: border-box;
}

.notifications {
    width: 327px;
    position: absolute;
    right: 20px;
    top: 80px;
    z-index: 10;
    max-height: calc(100vh - 150px);
    overflow: hidden;
    & > div {
        color: #ffffff;
        font-size: 14px;
        margin-bottom: 10px;
    }

    & .header {
        padding: 11px 0px 9px 13px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        color: #ffffff;
        display: flex;
        align-items: center;
        font-weight: 500;
        justify-content: center;
    }

    & .footer {
        flex-grow: 1;
        height: 41px;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        & .button {
            cursor: pointer;
            flex-basis: 50%;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;

            &:first-child {
                border-right: 1px solid rgba(255, 255, 255, 0.1);
            }
        }
    }
}

.error {
    max-height: calc(100vh - 150px);
    right: 20px;
    top: 80px;
}
