.root.highPriority {
    position: absolute;
    left: 10px;
    bottom: 70px;
    padding: 6px;
    height: auto;
    background: rgba(97, 97, 97, 0.8);
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
    width: auto;
}
