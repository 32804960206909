.block {
    display: flex;
    gap: 5px;
    margin-bottom: 32px;
    color: #48587d;

    &Started {
        color: #ffffff;
    }

    &_smallScreen {
        display: none;

        @media (max-width: 768px) {
            display: flex;
        }
    }

    & .text {
        align-self: center;
        font-weight: 500;
        font-size: 14px;
        flex-basis: 205px;
        flex-shrink: 0;

        @media (max-width: 768px) {
            flex-basis: 100px;
        }

        &_start {
            align-self: start;
        }
    }

    .fields {
        display: flex;
        font-weight: normal;
        font-size: 14px;
        margin-right: auto;
        align-items: center;
        flex-basis: 445px;
        flex-shrink: 1;

        & > .radioButton {
            margin-right: 27px;
        }

        & > span {
            margin: 0 8px;
        }
        [data-input-wrapper] {
            width: 100%;
        }

        &_complex {
            flex-wrap: wrap;
            [data-input-wrapper] {
                width: auto;
            }
        }

    }
}
