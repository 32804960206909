.form {
    height: 288px;
    width: 368px;
    margin: 0 auto;
}

.title {
    text-align: center;
    font-size: 42px;
    color: #48587d;
    margin-bottom: 40px;
    margin-top: 107px;
}
