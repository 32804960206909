.conferenceParticipantsList {
    &__participant {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 16px;
        background: transparent;
        color: #fff;
        transition: background 0.3s;

        &:hover {
            background: rgba(255, 255, 255, 0.05);

            .conferenceParticipantsList {
                &__participantKebab {
                    visibility: visible;
                    opacity: 1;
                }
               &__participantStatus {
                   display: none;
                   &_mobile {
                       display: block;
                   }
               }
            }
        }

        &_mobile {
            border-bottom: 1px solid #373a3e;
            background: #242527;
            &:first-child {
                border-top: 1px solid #373a3e;
            }
        }
    }

    &__participantKebab {
        visibility: hidden;
        opacity: 0;
        margin-left: auto;

        &_mobile {
            opacity: 1;
            visibility: visible;
        }
    }

    &__participantStatus {
        display: block;

        &_mobile {
            display: block;
        }
    }

    &__nameBlock {
        margin-left: 10px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        flex-grow: 1;
        overflow-wrap: break-word;
    }

    &__participantName {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-width: 145px;
    }

    &__guestLabel {
        font-size: 14px;
        font-weight: 400;
        opacity: 0.5;
        align-self: center;
    }
}

.spinner {
    & > div {
        left: 20px;
    }
}
