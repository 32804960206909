@import '../../../../styles/mixins';

.videoView {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 0 0;
}
html .avatarsOnly {
    --gap: 80px;
    position: absolute;
    top: 60px;
    left: 0px;
    right: 0px;
    bottom: 80px;
    display: grid;
    align-items: center;
    overflow: auto;
    @include scrollbar;

    .scroll {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: var(--gap);
        width: 100%;
    }
    @media screen and (max-width: 640px) {
        --gap: 40px;
    }
}
