.upcomingConferencesEmpty {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:max-content;
    margin: 50px auto 20px;
    font-size: 18px;
    font-weight: 500;
    color: #646E8B;

    &__btn {
        max-width: 274px;
        margin-top: 20px;
        white-space: nowrap;
    }
}
