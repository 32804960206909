.participants {
    position: relative;
    height: 100%;
}

.membersCount {
    position: absolute;
    color: #45d05b;
    font-weight: 500;
    font-size: 14px;
    font-family: Seravek;
    right: 25px;
    top: 0px;
    font-weight: 500;
}
