.startInfoWrapper {
    font-size: 16px;
    font-weight: 500;
    text-align: center;

    & > :first-child {
        display: block;
    }
}

.videoCallMembers {
    color: #ffffff;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.header {
    font-weight: 500;
    margin-bottom: 13px;
}

.conferenceOwner {
    margin-bottom: 13px;
}

.membersBlock {
    display: flex;
    justify-content: flex-start;
    margin-right: -6px;
    margin-left: -6px;
    flex-wrap: wrap;
    max-height: 160px;
    overflow: auto;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #b7bbc0;
        outline: none;
        border-radius: 8px;
    }
}

.button {
    margin: auto auto 0;
    max-width: 340px;
    width: 100%;
}

.spinner {
    display: flex;
    justify-content: center;
}
