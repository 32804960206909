.button {
    padding: 10px 16px;
    display: flex;
    align-items: center;
    gap: 6px;
    background-color: var(--ultramarine-blue);
    border: none;
    border-radius: 30px;
    color: var(--white);
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    &:enabled:hover {
        cursor: pointer;
        background-color: var(--blueberry);
    }

    &:disabled {
        cursor: default;
        color: #9095b2;
        background: #e9ebf5;
    }
}
