.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
    position: relative;
    color: #48587d;
    font-weight: 500;
    font-size: 20px;
}

.dates {
    margin: 21px 0;
}

.filters {
    display: flex;
    margin-top: 40px;
    font-weight: 500;
    font-size: 16px;
    color: #48587d;

    &__item {
        cursor: pointer;
        flex-shrink: 0;
        padding-left: 3px;
        padding-right: 3px;

        &:last-child {
            margin-left: 64px;
        }
        &_active {
            color: #2493fb;
            padding-bottom: 6px;
            border-bottom: 2px solid #2493fb;
        }
    }
}

.conferences {
    margin-top: 44px;
}

.group {
    color: #777f95;
    font-weight: 500;
    font-size: 14px;
    &__date {
        margin-bottom: 20px;
    }
}
