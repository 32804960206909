.conferenceChat__alertButton {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 7px 0;
    background: #fff;
    border: none;
    color: #2493FB;
    font-size: 13px;
    cursor: pointer;
}