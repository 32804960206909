.form {
    overflow-y: auto;
    height: 100%;
    width: 100%;
    background-color: #f6f7fc;
    display: flex;
    flex-direction: column;

    &__select {
        width: 120px;
        [data-js-select-header] {
            justify-content: center;
        }
    }

    .submitErrorWrapper {
        margin-top: 15px;
    }

    .block {
        display: flex;
        flex-direction: column;

        .text {
            padding: 0 0 7px 14px;
            color: #48587d;
            text-transform: uppercase;
            font-weight: normal;
            font-size: 12px;

            &_light {
                color: #ffffff;
            }
        }
    }

    .header {
        padding: 62px 14px 0;
        display: flex;
        justify-content: space-between;

        & button {
            color: #2493fb;
            font-size: 14px;
            font-weight: 500;
            background-color: transparent;
            &[disabled] {
                opacity: .5;
            }
        }

        &_disabled {
            & button {
                color: #777f95;
            }
        }
    }

    .timeZone {
        font-weight: 400;
        font-size: 14px;
        height: 46px;
        width: 100%;
        display: flex;
        align-items: center;
        color: #48587d;

        & > div {
            width: 100%;
            height: 100%;
        }
    }

    .likeInput {
        border: none;
        border-top: 1px solid #e9ecf4;
        border-bottom: 1px solid #e9ecf4;
        font-weight: normal;
        font-size: 14px;
        padding: 0 14px;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        color: #48587d;
        box-sizing: border-box;
        width: 100%;
        align-items: center;
        height: 46px;

        &_disabled {
            background-color: #f9f9f9;
        }

        &:not(:first-child) {
            margin-top: 8px;
        }

        & input {
            border: none;
            padding: 0;
            font-size: 14px;
            font-weight: normal;
            height: auto;
            text-align: right;
            width: 85px;
        }

        & .duration {
            display: flex;
            align-items: center;

            & input {
                width: 16px;
            }

            & span {
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }

    .note {
        color: #777f95;
        font-weight: 400;
        font-size: 14px;
        padding: 5px 14px 0;
    }

    .button {
        margin-top: auto;
        margin-bottom: 22px;
        padding: 0 12px;
    }
}

.multi_row {
    .like_input {
        border: none;
        border-top: 1px solid #e9ecf4;
        border-bottom: 1px solid #e9ecf4;
        font-weight: normal;
        font-size: 14px;
        padding: 0 14px;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        color: #48587d;
        box-sizing: border-box;
        width: 100%;
        align-items: center;
        height: 46px;

        &_disabled {
            background-color: #f9f9f9;
        }

        &:not(:first-child) {
            margin-top: 8px;
        }

        & input {
            border: none;
            padding: 0;
            font-size: 14px;
            font-weight: normal;
            height: auto;
            text-align: right;
            width: 85px;
        }

        & .duration {
            display: flex;
            align-items: center;

            & input {
                width: 16px;
            }

            & span {
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }
}

.margin_top_29 {
    margin-top: 29px;
}

.margin_top_14 {
    margin-top: 14px;
}

.margin_top_24 {
    margin-top: 24px;
}

.margin_top_10 {
    margin-top: 10px;
}

.margin_top_17 {
    margin-top: 17px;
}

.margin_top_31 {
    margin-top: 31px;
}

.margin_bottom_40 {
    margin-bottom: 40px;
}
