.conferenceTime {
    display: grid;
    place-content: center;
    place-items: center;
    padding-right: 57px;
    gap: 4px;

    @media (max-width: 992px) {
        grid-area: time;
        padding-right: 20px;
    }

    @media (max-width: 765px) {
        place-content: start;
        padding-bottom: 5px;
        border-bottom: 1px solid #e9ebf5;
    }

    &_blue {
        .conferenceTime__range {
            color: #1c88ec;
        }
    }

    &__range {
        font-size: 18px;
        text-align: center;
        color: #48587d;
    }

    &__started {
        display: flex;
        align-items: center;
        color: #1c88ec;

        &::before {
            content: '';
            position: relative;
            display: inline-block;
            width: 17px;
            height: 17px;
            margin-right: 6px;
            background: url('../../../../../../../assets/Timer.svg') center center no-repeat;
        }
    }

    &__beforeStart {
        font-size: 14px;
        color: #6aaa64;
    }
}
