.member {
    position: relative;
    background-color: #000000;
    display: flex;
    margin: 5px;

    & video {
        width: 100%;
        position: relative;
    }

    &:hover > .hoverMenu {
        display: flex;
    }

    &_mirrored {
        video {
            transform: rotate3d(0, 100, 2, 180deg);
        }
    }

    &_withPriorityFrame {
        & .iconsRightBlock {
            top: 70px;
            flex-direction: column;

            & div:not(:nth-child(1)) {
                margin-top: 6px;
            }
        }

        & .squareOwner {
            position: static;
        }

        & .hoverMenu {
            width: auto;
            height: max-content;
            bottom: 80px;
            display: flex;
            background: rgba(0, 0, 0, 0.6);
            border-radius:25px;
            padding: 5px 0;
            padding-right: 5px;
            & > div {
                margin-left: 8px;
            }
        }
    }
}

.menuOpen {
    & > .hoverMenu {
        display: flex;
    }
}

.block {
    background-color: #000000;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.squareOwner {
    position: absolute;
    z-index: 5;
}

.iconsRightBlock {
    position: absolute;
    right: 0;
    z-index: 5;
    display: flex;
}

.iconWrapper {
    width: 37px;
    height: 37px;
}

.active {
    outline: 2px solid #2493fb;
}

.hoverMenu {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 37px;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 11px 12px 9px 12px;
    box-sizing: border-box;
    width: 100%;
    display: none;
    justify-content: space-between;
    z-index: 4;
    align-items: center;

    &_visible {
        display: flex;
    }

    & .name {
        font-weight: 500;
        font-size: 14px;
        font-family: 'Seravek', sans-serif;
        color: #ffffff;
    }
}

.emotion {
    opacity: 0.7;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    max-width: 200px;
    max-height: 200px;
    width: 40%;
    height: 40%;

    &_priorityFrame {
        bottom: 50px;
    }
}

.mediaDevice {
    transition: fill 0.3s;

    &__forbidden {
        path {
            fill: #ec6e6e;
        }
        rect {
            fill: #ec6e6e;
        }
    }

    &__disabled {
        path {
            fill: #474747;
        }
        rect {
            fill: #474747;
        }
    }
}

.square {
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
