.name {
    font-size: 24px;
    margin-bottom: 31px;
    font-weight: 500;
    color: #48587d;
}

.annotation {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
    color: #777f95;
}

.inputId {
    margin-bottom: 18px;
}

.issueText {
    height: 30px;
    margin-bottom: 20px;
}
