.ConferenceChatForm {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 18px 10px;
    &_mobile {
        justify-content: flex-start;
        padding: 18px 10px 30px;
    }

    &__labelAttach {
        position: relative;
        width: 20px;
        height: 20px;
        cursor: pointer;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            height: 20px;
            background: url('../../../../../assets/Clip.svg') center center no-repeat;
        }
    }

    & &__input {
        padding: 0 10px 0 20px;
        background: transparent;
        border-radius: 0;
        border: none;
        color: #adb9cf;
        font-size: 13px;
    }

    &__inputWrapper {
        position: relative;

        &_mobile {
            display: flex;
            width: 100%;
            margin-left: 15px;
            border-radius: 15px;
            background: #494949;

            & > div {
                &:first-child {
                    width: 100%;
                }
                &:last-child {
                    margin-right: 15px;
                }
            }
        }
    }

    input[type='file'] {
        position: absolute;
        width: 1px;
        height: 1px;
        visibility: hidden;
    }

    input[type='text'] {
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            border: 1px solid #989898;
            -webkit-text-fill-color: #adb9cf;
            -webkit-box-shadow: 0 0 0px 1000px #353535 inset;
        }
    }
}

.popupDialog {
    &Title {
        font-weight: 500;
        font-size: 16px;
    }

    &_sm {
        min-width: 200px;
        min-height: 160px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 10px 8px 8px;
    }
}

.msgLengthWarning {
    font-weight: 500;
    font-size: 10px;
    color: #adb9cf;
    position: absolute;
    left: 20px;
    top: -3px;
}

.msgLengthWarningIsBlinked {
    color: #d05353;
}
