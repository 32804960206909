@import '../../../styles/animations.scss';

.usersList {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 280px;
    height: auto;
    max-height: 167px;
    min-height: 40px;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid #e2e7ef;
    border-radius: 6px;
    background: #fff;
    @include fadeInAnimation(0.5s);
    z-index: 50;
    scrollbar-width: thin;

    &_transform {
        transform: translate(-105%, 20px);
    }

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #b7bbc0;
        outline: none;
        border-radius: 8px;
    }
}

.user {
    display: flex;
    align-items: flex-start;
    padding-top: 6px;
    padding-left: 7px;
    cursor: pointer;

    &:hover {
        background: #eaf5ff;

        .user__info::after {
            background: #eaf5ff;
        }
    }

    &[data-active='true'] {
        background: #2493fb;
        * {
            color: #fff;
        }

        .user__info::after {
            background: #2493fb;
        }
    }

    &__info {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-left: 5px;
        padding-bottom: 5px;
        font-size: 12px;
        font-weight: normal;
        color: #8998b4;

        &::after {
            position: absolute;
            content: ' ';
            width: 21em;
            height: 1px;
            left: 0;
            bottom: 0;
            background: #ebf3ff;
            z-index: 0;
        }
    }

    &__infoName {
        font-weight: 500;
        line-height: 14px;
        color: #48587d;
    }

    &__infoMail {
        line-height: 14px;
    }

    &__avatar {
        max-width: 26px;
        max-height: 26px;
        border-radius: 8px;
        object-fit: cover;
    }
}

.loaderWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    margin: auto;
}

.notFound {
    margin: auto;
    font-weight: 500;
}
