$border-thickness: 2;

.arrow {
    position: absolute;
    transform: rotate(45deg);

    &_small {
        width: 3px;
        height: 3px;
    }

    &_medium {
        width: 4px;
        height: 4px;
    }

    &_large {
        width: 8px;
        height: 8px;
    }

    &_down {
        border-left: none;
        border-top: none;
        border-right: $border-thickness + px solid #fff;
        border-bottom: $border-thickness + px solid #fff;
    }

    &_up {
        border-right: none;
        border-bottom: none;
        border-left: $border-thickness + px solid #fff;
        border-top: $border-thickness + px solid #fff;
    }

    &_left {
        border-right: none;
        border-bottom: $border-thickness + px solid #fff;
        border-left: $border-thickness + px solid #fff;
        border-top: none;
    }

    &_right {
        border-right: $border-thickness + px solid #fff;
        border-bottom: none;
        border-left: none;
        border-top: $border-thickness + px solid #fff;
    }

    &_white {
        border-color: #fff;
    }

    &_gray {
        border-color: #aab1c6;
    }

    &_blue {
        border-color: #2493FB;
    }

    &_red {
        border-color: #ec6e6e;
    }

    &_border-bold {
        border-width: 3px;
    }
}
