.shareDialog {
    &__section {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &Header {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        &Title {
            margin: 0;
            font-weight: 500;
            font-size: 18px;
        }

        &Description {
            line-height: 24px;
        }
    }
}
