.wrap {
    position: relative;
    .count {
        display: none;
    }
    &.isFocused {
        .count {
            display: block;
            position: absolute;
            top: -20px;
            right: 0;
            color: #aab1c6;
            font-size: 12px;
            line-height: 14px;
        }
    }
    &.isBlinked {
        .count {
            color: #ff3055;
        }
    }
}
