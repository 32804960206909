.darkTheme {
    &.selectItemInList {
        color: #ffffff;
        background: #ffffff26;
    }
    &.item {
        &:hover {
            background: #ffffff0d;
        }
    }
}

.selectItemInList {
    font-weight: 500;
    color: #48587d;
}

.item {
    display: flex;
    align-items: center;
    padding: 7px 16px;
    cursor: pointer;

    &:hover {
        background: #eaf5ff;
    }
}
.hoverWrapper {
    display: flex;
    position: absolute;
    bottom: 93%;
    left: 50%;
    height: min-content;
    transform: translateX(-50%) translateY(-15%);
    white-space: nowrap;
    padding: 10px;
    z-index: 20;
}
