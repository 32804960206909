.container {
    &__plus {
        position: relative;
        width: 16px;
        height: 16px;
        &::after,
        &::before {
            top: 50%;
            left: 50%;
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #4176fa;
            transition: 0.3s background;
        }

        &::after {
            transform: translate(-50%, -50%) rotate(90deg);
        }

        &::before {
            transform: translate(-50%, -50%);
        }
    }

    &__submitBtn {
        display: flex;
        align-items: center;
        gap: 6px;
        margin-top: 25px;
        padding: 10px 16px;
        color: #4176fa;
        font-weight: 500;
        font-size: 16px;
        border: 1px solid #4176fa;
        border-radius: 30px;
        background-color: transparent;
        transition: 0.3s background, 0.3s color;
        cursor: pointer;
        
        &:hover {
            color: #ffffff;
            border: 1px solid #ffffff;
            background-color: #4176fa;

            .container__plus {
                &::after,
                &::before {
                    background: #ffffff;
                }
            }
        }
    }
}
