.form {
    width: 394px;
    background: rgba(0, 0, 0, 0.85);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 48px 25px;

    & > img {
        margin-bottom: 21px;
    }
}

.headerText {
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 27px;
}

.text {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
}

.inputStyle {
    border-radius: 8px;
    height: 37px;
    font-size: 14px;
    padding: 10px 13px;
}

.button {
    border-radius: 8px;
    height: 37px;
}
.statusLabel {
    margin: 0 0 20px 0;
}
