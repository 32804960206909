.systemMessage {
    display: flex;
    align-items: center;
    flex-grow: 0;
    margin: 0 auto 20px;
    padding: 3px 15px;
    color: #ffffff;
    font-size: 13px;
    font-weight: 400;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    max-width: max-content;

    &__tooltip {
        position: absolute;
        bottom: calc(100% + 6px);
        display: none;
    }

    &_overflow span:first-of-type {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:hover > &__tooltip_neverShow {
        display: none;
    }

    &:hover > div {
        display: block;
    }

    & span:not(:first-of-type) {
        & span {
            white-space: nowrap;
        }
    }

    & > span:first-of-type {
        margin-right: 5px;
        font-weight: 500;
        white-space: nowrap;
    }

    & span:last-of-type {
        & > span:last-of-type {
            margin-left: 10px;
            font-size: 12px;
            color: #9d9d9d;
        }
    }
}
