.likeH3,
.likeH1 {
    text-align: center;
}

.likeH3 {
    font-size: 20px;
    color: #777f95;
    font-weight: 300;
    margin-bottom: 10px;
}

.likeH1 {
    font-size: 65px;
    font-weight: 500;
    color: #48587d;
    margin-bottom: 1.5em;

    @media (max-width: 1280px) {
        font-size: 50px;
    }
}

.formGroup {
    display: flex;
    justify-content: space-between;

    &__right {
        box-sizing: border-box;
        margin-right: 99px;
        min-width: 340px;
        margin-left: 161px;
        flex-basis: 516px;
        flex-shrink: 1;
    }

    &__left {
        max-width: 516px;
        box-sizing: border-box;
        margin-left: 88px;
        flex-basis: 516px;
        flex-shrink: 1;

        & .createBlock {
            display: flex;
            flex-direction: column;
            min-height: 100%;
            flex-wrap: wrap;
        }
    }
}

.blockOr {
    font-size: 28px;
    font-weight: 200;
    color: #aab1c6;
    position: absolute;
    top: 397px;
    left: 50%;
    transform: translateX(-50%);
}

.mainSection {
    max-width: 1440px;
    margin: 47px auto;
}

.dialogContainer {
    padding: 25px;
}
