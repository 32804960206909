.background {  
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dialog {
    width: 604px;
    background: #ffffff;
    border-radius: 12px;
    padding: 28px 45px 43px;
    box-sizing: border-box;
    opacity: 1;
    position: relative;
}

.cross {
    position: absolute;
    right: 20px;
    top: 23px;
}