.wrapper {
    min-width: 116px;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: calc(-100% - 5px);
    z-index: 5;

    &_inChat {
        min-width: unset;
        top:unset;
        bottom: 100%;
    }

    .item {
        margin: 0 12px;
        width: 20px;
        cursor: pointer;
    }
}
