@keyframes mobileFadeIn {
    from {
        transform: translateX(-100%)
    }
    to {
        transform: translateX(0)
    }
}

.conferenceChat {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-basis: 290px;
    flex-grow: 1;
    flex-shrink: 0;
    background: #333333;
    max-width: 290px;

    &_mobile {
        position: absolute;
        left: 0;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        width: 100%;
        height: 100%;
        z-index: 5;
    }
    &_show {
        left: 0;
    }

    &__messagesWrapper {
        position: relative;
        overflow: hidden;
        height: 100%;
    }

    &__messagesContainerWrapper {
        position: relative;
        height: 100%;
        padding: 0 10px;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
        }
    }

    &_fullScreen {
        max-width: 100%;
        height: 100%;
    }
}
