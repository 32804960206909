.header {
    padding: 12px 30px;
    display: flex;
    justify-content: space-between;
    gap: 34px;
    border-bottom: 1px solid transparent;
    align-items: center;

    & button {
        display: flex;
        align-items: center;
        justify-content: center;

        & > img {
            margin-right: 11px;
        }
    }

    .logo {
        width: 110px;
    }

    min-height: 75px;
    box-sizing: border-box;
}

.logo {
    cursor: pointer;
    width: 153px;
}

.buttonGroup {
    display: flex;
    align-items: center;
    gap: 34px;
}

.exitBtn {
    position: relative;
    display: flex;
    align-items: center;

    & > div {
        position: relative;
    }
}

.logoWrapper {
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    > span {
        position: absolute;
        bottom: -6px;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 8px;
        color: rgba(200, 200, 200, 0.8);
        user-select: text;
    }
}

.withBorder {
    position: sticky;
    top: 0;
    z-index: 15;
    border-bottom: 1px solid #e5e5e5;
    background-color: #ffffff;
}

.button {
    &_134 {
        width: 134px;
    }

    &_152 {
        width: 152px;
    }
}

.exitImgContainer {
    max-width: 18px;
    max-height: 20px;
    margin-right: 10px;
}
