.background {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.dialog {
    background: #ffffff;
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0 30px;

    .footer button{
        color: #2493fb;
    }

    &_red {
        .footer button{
            color: #D05353;
        }
    }
}

.header {
    font-size: 14px;
    color: #48587d;
    padding: 15px 30px 30px;
    text-align: center;
}

.footer {
    display: flex;
    flex-direction: column;

    button {
        box-sizing: border-box;
        border: none;
        border-top: 1px solid #e9ecf4;
        padding: 13px 40px;
        font-size: 14px;
        text-align: center;
        background: transparent;
        cursor: pointer;
    }

    .primaryAction {
        font-weight: 700;
    }
}
