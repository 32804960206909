.progressBar {
    width: 100%;
    height: 4px;
    background: #e9ecf4;
    border-radius: 2px;
    overflow: hidden;

    &__progress {
        height: 100%;
        background: #2493fb;
        border-radius: 2px;
    }
}
