.container {
    max-width: 180px;
    flex-basis: 180px;
    border-radius: 6px;
    border: 1px solid #e2e7ef;
    background: #ffffff;
    transition: background 0.3s;

    &_hover {
        &:not(.container_active):hover {
            background: #e2e7ef;
            .container__label {
                font-weight: 500;
            }
        }
    }

    &_active {
        background: #2c5cba;
        .container__label {
            font-weight: 500;
            color: #ffffff;
        }
    }
    
    img {
        max-width: 180px;
        width: 100%;
    }

    &__button {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    &__label {
        display: inline-block;
        max-width: 180px;
        width: 100%;
        padding: 4px 8px;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: #253e71;
        white-space: nowrap;
        text-overflow: ellipsis;
        background: transparent;
        border-top: 1px solid #e2e7ef;
        box-sizing: border-box;
        overflow: hidden;
        transition: color 0.3s;
    }
}
