@import '../../../styles/animations.scss';

.mobileNotification {
    background: #defac8;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Roboto;
    font-size: 14px;
    line-height: 140%;
    color: #35772a;
    padding: 6px 12px;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
    width: 100%;
    @include fadeInAnimation(0.2s);

    .title {
        font-weight: 700;
    }

    .text {
        width: max-content;
        margin: 0;
        font-weight: normal;
    }

    &__action {
        display: flex;
        align-items: center;

        .actionButton {
            height: auto;
            padding: 4px 8px;
            border-radius: 4px;
        }

        .actionArrow {
            transform: rotate(180deg);
            transition: transform 0.2s;
            * {
                fill: #35772a;
            }
        }

        .actionButton {
            background: #35772a;
            &:hover {
                background:#35772a;
            }
        }
    }

    &_interactive {
        cursor: pointer;
    }

    &_error {
        background-color: #ffe5df;
        .title {
            color: #d05353;
        }
        .text {
            color: #d05353;
        }
    }
}

.itemFadeOut {
    @include fadeOutAnimation(0.2s);
}
