@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInWithScale {
    0% {
        opacity: 0;
        transform: scaleY(0);
    }
    100% {
        transform: scaleY(1);
        opacity: 1;
    }
}

@keyframes fadeOutWithScale {
    0% {
        opacity: 1;
        transform: scaleY(1);
    }
    100% {
        transform: scaleY(0);
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@mixin spinAnimation {
    animation: spin 2000ms linear infinite;
}

@mixin fadeInAnimation($duration: 0.3s, $timingFunc: linear, $direction: forwards) {
    animation: fadeIn $duration $timingFunc $direction;
}

@mixin fadeInWithScaleAnimation($duration: 0.3s, $timingFunc: linear, $direction: forwards) {
    animation: fadeInWithScale $duration $timingFunc $direction;
}

@mixin fadeOutAnimation($duration: 0.3s, $timingFunc: linear, $direction: forwards) {
    animation: fadeOut $duration $timingFunc $direction;
}

@mixin fadeOutWithScaleAnimation($duration: 0.3s, $timingFunc: linear, $direction: forwards) {
    animation: fadeOutWithScale $duration $timingFunc $direction;
}

@mixin stretchUpAndFadeIn_initial {
    visibility: hidden;
    opacity: 0;
    transform: scaleY(0);
    transition: opacity 0.3s, transform 0.4s;
}

@mixin stretchUpAndFadeIn_animate {
    visibility: visible;
    opacity: 1;
    transform: scaleY(1);
    transition: opacity 0.3s, transform 0.4s;
}
