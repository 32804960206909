.container {
    display: flex;
    flex-direction: column;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    background: transparent;
    color: #48587d;
    align-items: center;

    input {
        visibility: hidden;
        position: absolute;
    }
}

.deviceCheckbox {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__img {
        height: 26px;

        path {
            fill: #48587d;
            transition: fill 0.3s;
        }
    }

    &__text {
        font-size: 10px;
        font-weight: 400;
        color: #48587d;
        transition: color 0.3s;
    }

    &_disabled {
        &::after {
            position: absolute;
            content: '';
            right: -8px;
            top: calc(50% - 12px);
            width: 10px;
            height: 12px;
            background: url('../../../../assets/LockMobile.svg') center center no-repeat;
            background-size: cover;
        }
    }

    &__imgWrapper {
        position: relative;
        overflow: hidden;
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 100%;
            width: 0;
            height: 2px;
            background: #a4adc0;
            z-index: 5;
            border-top: 2px #ffffff solid;
            transform: rotate(45deg);
            transition: 0.3s;
        }

        &_audioOff {
            &:before {
                left: -2px;
                width: 120%;
                bottom: 51%;
            }
        }

        &_videoOff {
            &:before {
                left: 0px;
                width: 90%;
                bottom: 51%;
            }
        }

        &_off {
            .deviceCheckbox__img {
                path {
                    fill: #a4adc0;
                }
            }
            & ~ .deviceCheckbox__text {
                color: #a4adc0;
            }
        }
    }
}
