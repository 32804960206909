.header {
    display: flex;
    flex-direction: column;

    & .dates {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    & .time {
        margin-top: 32px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
    }
}

.dateWrapper {
    display: flex;
    align-items: center;
}

.dateField {
    max-width: 150px;
    margin-left: 7px;
    border-radius: 8px;

    &__statusText {
        position: absolute;
    }

    &__time {
        max-width: 110px;
    }
}

.label {
    font-weight: 500;
    font-size: 14px;
    color: #48587d;

    &_first {
        width: 80px;
        flex-shrink: 0;
    }
}

.img {
    width: 42px;

    &_disabled * {
        fill: #e5e8ed;
    }
}

.block_mobile {
    div .input_mobile,
    input {
        background-color: #f8f9fc;
    }

    input {
        color: #2493fb;
    }
}
