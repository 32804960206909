@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

.App {
    /* height: 100%; */
    font-family: 'Roboto', sans-serif;
}

label {
    user-select: none;
}

button {
    padding: 0;
    border: none;
}

.react-datepicker__day--disabled .react-datepicker__dayNumber {
    color: #ccc;
}
.react-datepicker .react-datepicker__day--disabled.react-datepicker__day:hover {
    background: transparent;
}
.react-datepicker .react-datepicker__day--disabled.react-datepicker__day:hover .react-datepicker__dayNumber {
    color: #ccc;
}
