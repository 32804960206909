.conferenceMainButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 992px) {
        grid-area: mainBtn;
    }
}

.conferenceMainButton {
    max-width: 175px;
    margin-right: 35px;
    padding: 0 25px;

    @media (max-width: 992px) {
        margin: 0;
    }
}
