.lobby {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    &__cancelBtn {
        width: max-content;
        margin: 10px auto;
    }
}

.lobbyMobile {
    display: flex;
    flex-direction: column;
    margin: auto;
    overflow: hidden;
}

.root {
    height: 100%;
    background: #121213;

    &_mobile {
        display: flex;
    }
}

.loader {
    &_mobile {
        max-width: 395px;
        width: 100%;
        height: unset;
        margin-bottom: 30px;
        border: none;
        background: transparent;
    }
}
.submitBtn {
    &_mobile {
        width: 95%;
        margin: 0 auto;
    }
}
