.modalInvite {
    font-family: 'Roboto';
    font-size: 18px;
    color: #363a53;

    &__title {
        font-family: 'Roboto';
        font-weight: 500;
    }

    &__description {
        margin-top: 10px;
        font-size: 16px;
        color: #646e8b;
    }

    &__formTitle {
        display: block;
        margin-top: 15px;
        margin-bottom: 10px;
    }
}
