.header {
  display: flex;
  align-items: center;
  min-height: 40px;
  position: relative;
  color: #48587d;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 20px;

  & > div {
    display: flex;
    align-items: center;
  }
}

.arrow {
  position: static;
  margin: 0 13px;
}
