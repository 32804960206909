.root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #121213;
    .content {
        display: flex;
        width: 100%;
        height: 100%;
        /*overflow: auto;*/
        .main {
            /*
            flex-shrink: 0;*/
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        .chat {
            flex-shrink: 0;            
            width: 100%;

            position: absolute;
            max-width: 100%;
            width: 100%;
            height: 100%;
            left: -100%;
            z-index: 8;
        }
    }
}
