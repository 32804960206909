a {
    text-decoration: none;
    color: #2493fb;
    font-size: 14px;
}

.form {
    color: #48587d;
    position: relative;

    & button {
        margin: 18px 0;
    }

    input {
        &::placeholder {
            color: #bac0ce;
        }
    }
}

.wrapper {
    margin-top: 71px;
    display: flex;

    & .buttons {
        &__save {
            width: 218px;
        }

        &__cancel {
            width: 186px;
            margin-left: 20px;
        }
    }
}

.avatar {
    margin-right: 101px;
    position: relative;

    &__delete {
        font-weight: 500;
        font-size: 14px;
        color: #ec6e6e;
        cursor: pointer;
        margin-top: 9px;
        text-align: center;
    }

    &__file {
        display: none;
    }
}

.information {
    position: relative;
    flex-basis: 457px;
    max-width: 457px;

    .email {
        &__change {
            font-size: 14px;
            color: #2493fb;
            cursor: pointer;
        }

        &__block {
            display: flex;
            justify-content: space-between;
        }
    }

    section {
        margin-bottom: 25px;
        font-size: 14px;

        label ~ div {
            margin-top:6px;
        }
    }

    &__label {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #48587d;
        display: inline-block;
    }

    &__userName {
        display: flex;
        justify-content: space-between;

        & > div {
            flex-basis: 215px;
        }
    }

    &__input {
        border-radius: 8px;
        height: 37px;
        font-size: 14px;
        padding: 10px 13px;
    }

    &_withMargin {
        margin-bottom: 25px;
        & label {
            margin-bottom: 6px;
        }
        font-weight: normal;
        font-size: 14px;
    }

    &_width100 {
        width: 100%;
        & > div {
            width: 100%;
        }
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
    }
}

.inactiveButton {
    background-color: gray;
}

.success {
    width: 270px;
    height: 40px;
    position: absolute;
    top: -111px;
    right: 40px;
}

.statusLabel {
    width: max-content;
    margin: 0 auto;
}