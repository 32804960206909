@import '../DynamicForm.module.scss';

.field {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;

    &__text {
        @include formBlockText;
    }

    &__title {
        @include formBlockTitle;
    }

    &__description {
        @include formBlockDescription;
    }

    &__select {
        max-width: 380px;
        width: 100%;
        margin-top: 12px;

        [data-js-select-header] {
            width: 100%;
        }
    }
}
