.buttons {
    display: flex;
    visibility: hidden;
    align-items: center;

    & > button {
        margin-right: 15px;

        &:not(:first-of-type) {
            &:hover {
                color: #2493fb;
                background-color: #ffffff;
                border: 1px solid #2493fb;
            }
        }
    }
}

.button {
    font-size: 14px;
    width: 98px;
    height: 33px;

    &_delete {
        border: 1px solid #48587d;
        color: #48587d;

        &:hover {
            color: #2493fb;
            background-color: #ffffff;
            border: 1px solid #2493fb;
        }
    }
}

.actionBtn {
    display: flex;
    width: auto;
    height: auto;
    margin-left: 18px;
    padding: 0;
    color: #2493fb;
    font-weight: 500;
    font-size: 13px;
    background: transparent;

    &:hover {
        background: transparent;
    }
}