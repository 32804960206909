.dialog {
    width: 895px;
    background: rgba(18, 18, 19, 0.85);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 99;
}

.header {
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    padding: 0 36px;
    display: flex;
    align-items: center;
    height: 66px;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    & .left {
        display: flex;
        align-items: center;
    }

    & img {
        margin-right: 10px;
    }
}

.form {
    padding: 32px 36px;

    @media screen and (max-width: 1500px) {
        padding: 16px 36px;
    }
}
