@keyframes slideRightToLeft {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

.conferenceParticipantsList {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    background: #121213;
    height: 100%;
    width: 100%;
    z-index: 20;
    animation: slideRightToLeft 0.4s ease-in-out;
    animation-fill-mode: forwards;
    &__header {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 22px 16px;
    }

    &__body {
        position: relative;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        color: #adb9cf;
    }

    &__backBtn {
        color: #2493fb;
        font-size: 14px;
    }

    &__title {
        margin: 0 16px 10px;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        text-transform: uppercase;
        color: #ffffff;
    }
}
