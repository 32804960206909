@mixin formBlockTitle {
    font-weight: 500;
    font-size: 16px;
    color: #48587d;
}

@mixin formBlockDescription {
    margin-top: 5px;
}

@mixin formBlockText {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 16px;
    color: #8690af;
}


.form {
    position: relative;
    max-width: 1000px;

    &_disabled {
        [data-form-control] {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}