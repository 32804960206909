.container {
    flex-grow: 1;
    @media (max-width: 768px) {
        flex-grow: 0.5;
    }

    [data-select-id] {
        flex-grow: 1;
    }
    [data-js-select-header] {
        width: auto;
    }

    & .block {
        &__top {
            display: flex;
            align-items: center;
            margin-left: 10px;

            @media (max-width: 768px) {
                margin-left: 0px;
            }

            & > span:first-child {
                margin-right: 10px;
            }

            &_noMargin {
                margin-left: 0px;
            }
        }
    }
}

.popupContainer {
    z-index: 15;
}

.popup {
    & > div:first-child {
        margin-bottom: 30px;
    }
}

.label {
    @media (max-width: 768px) {
        display: none;
    }
}
