.container {
    position: absolute;
    left: 0;
    width: 100%;
    transform: rotateX(0) translateY(0%);
    transform-origin: top;
    transition: transform 0.2s;

    & > div {
        justify-content: flex-start;
    }
}