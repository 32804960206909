.dialog {
    padding: 25px 32px;
    border-radius: 36px;

    &__header {
        font-weight: 500;
        font-size: 21px;
        color: #363a53;
    }

    &__description {
        display: inline-block;
        max-width: 400px;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #9095b2;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__content {
        min-width: 300px
    }

    &__controls {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 25px;
        margin-top: 30px;
    }

    &__btn {
        font-weight: 500;
        transition: 0.3s background, 0.3s color;
        &_delete {
            background: #dd4e2e;

            &:enabled:hover {
                background: #e26f55;
            }
        }

        &_cancel {
            color: #363a53;
            background: #e2e9ef;

            &:enabled:hover {
                background: #e8ecee;
            }
        }
    }
}
