.form {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.title {
    display: block;
    margin: 22px 16px 0px;
    font-size: 24px;
    font-weight: normal;
    line-height: 33px;
    color: #48587d;
    text-align: left;
}
