.contextMenu {
    position: absolute;
    top: 100%;
    right: 50%;
    background: #ffffff;
    box-shadow: 0 3px 10px #dbdbdb;
    z-index: 20;

    &_top {
        top: 0;
        transform:translateY(-100%)
    }

    &__button {
        width: 100%;
        text-align: start;
        padding: 8px 14px 8px 0;
        border-radius: 0;
        transition: box-shadow 0.2s;
        &:hover {
            box-shadow: 0px 3px 10px #dbdbdb;
        }
    }

    &__buttonContent {
        display: grid;
        grid-template-columns: 40px 1fr;
        font-weight: 400;
        font-size: 16px;
        color: #646e8b;

        &_red {
            color: #f55b76;
        }

        img {
            margin: 0 auto;
        }

        span {
            white-space: nowrap;
        }
    }

    &__item:first-child {
        img {
            width: 18px;
        }
    }
}
