.header {
    position: absolute;
    display: flex;
    width: 100%;
    color: #ffffff;
    padding: 12px 30px;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    min-height: 70px;
    box-sizing: border-box;
    transition: transform 0.3s;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    z-index: 10;

    &__conferenceName {
        white-space: nowrap;
        line-height: 32px;
        padding: 0 20px;
    }

    &__left {
        display: flex;
        align-items: center;
    }
}
