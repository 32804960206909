.img {
  border-radius: 50%;
}

.placeholder {
  border-radius: 50%;
  background-color: var(--pale-cornflower-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);

  svg {
    width: 40%;
    height: 40%;
  }
}
