.wrapper {
    display: flex;
}

.messagesContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: auto;
    width: 100%;
    min-height: 100%;
    background: transparent;
}
