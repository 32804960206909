.view {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    flex-shrink: 0;
    color: #ffffff;
    & > img {
        margin-right: 5px;
    }
}

.arrow {
    position: relative;
    top: -1px;
    margin-left: 5px;
}

.dropDownContainer{
    position: absolute;
    top: 30px;
    z-index: 10;
}

.dropDown {
    padding: 5px 0;
    background-color: #202020;
    color: white;
    white-space: nowrap;
    border-radius: 4px;
    font-size: 16px;
    line-height: 25px;

    > ul {
        padding: 0 15px 0 30px;
        position: relative;
        &:hover {
            background-color: #404040;
        }
        &.checked span {
            &:before {
                content: '';
                background: url("/assets/Check.svg") center center no-repeat;
                position: absolute;
                width: 15px;
                height: 15px;
                left: 10px;
                top: 4px;
            }
        }
    }
}
