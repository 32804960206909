@import '../../../styles/mixins.scss';

.container {
    @include mobilePopupContainer;
    padding-top: 50px;
}

.header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 16px;
}