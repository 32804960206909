
.planeConfGroup {
    display: flex;
    gap: 6px;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #2493fb;
    cursor: pointer;
    text-decoration: none;
    line-height: 2;
}