.avatarListWrapper {
    width: 100%;
}

.avatarList {
    display: flex;
    justify-content: flex-end;
    padding: 5px 20px;
    > div {
        display: flex;
        justify-content: flex-start;
        max-width: 100%;
        > div {
            margin: 0 5px;
        }
    }
}

.displayNone {
    display: none;
}

.participant {
    position: relative;
}

.emotion {
    position: absolute;
    bottom: calc(-100% + 2px);
    transform: translateX(-50%);
    left: 50%;

    & img {
        padding: 7px;
    }
}

.tooltipWrapper {
    position: relative;
    height: 1px;
    margin: 0 20px;

    &_hover {
        .tooltipLabel {
            visibility: visible;
            opacity: 1;
        }
    }
}

.tooltipLabel {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    top: calc(100%);
    right: 0;
    max-width: 150px;
    width: max-content;
    padding-top: 8px;
    margin: 0 5px;
    transform: translateX(50%);
    transition: opacity 0.2s;
    font-size: 12px;
    font-weight: 400;
    font-family: Roboto;
    color: #fff;
    border-radius: 4px;
    text-align: center;
    z-index: 13;
}