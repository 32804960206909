.pageWrapper {
    background: #121213;
    color: #FFFFFF;

    &__header {
        & > button:first-of-type {
            display: none;
        }
    }

    &__title {
        color: #FFFFFF;
    }
}

.finishPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &__information {
        display: grid;
        place-items: center;
        margin: auto 0;

        img {
            margin-bottom: 20px;
        }
    }

    &__exitBtn {
        max-width:350px;
        margin: 0 auto 24px;
        border-radius: 12px;
    }
}