.root {
    height: 76px;
    width: 100%;
    display: grid;
    grid-template-columns: 15px 30px 1fr 60px;
    gap: 20px;
    padding: 10px 10px 0 10px;
    align-items: center;
    box-sizing: border-box;
    background: #000000;

    > :first-child {
       padding: 5px 10px;
    }

    > :nth-child(2) {
        display: flex;
        align-items: center;
        button {
            height: auto;
            min-height: unset;
            padding: 0;
        }
    }

    > :last-child {
        width: auto;
        height: 24px;
        z-index: 9;
    }
}
