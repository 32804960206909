li {
    list-style-type: none;
}

ul {
    padding: 0;
    margin: 0;
}

.menu {
    position: relative;
}

.dots {
    cursor: pointer;
    width: 15px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div {
        width: 4px;
        height: 18px;
        background-image: radial-gradient(circle, #ffffff 2px, transparent 2px);
        background-size: 100% 33.33%;
    }
}
