.conference__controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    transform-origin: bottom;
    transform: rotateX(90deg) translateY(50%);
    transition: transform 0.2s;

    @media (max-width: 992px) {
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 10px;
    }

    &Button {
        position: relative;
        width: max-content;
        padding: 8px 24px;
        transition: opacity 0.3s;
        @media (max-width: 992px) {
            // position: absolute;
            // transform: scale(0);
        }

        & > *:last-child {
            display: none;
            position: absolute;
            bottom: 50px;
            left: -10px;
            width: 100%;
        }


        &:hover {
            & > *:last-child {
                display: block;
            }
        }
    }
}
