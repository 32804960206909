@import '../../styles/mediaQueries.scss';

.form {
    max-width: 368px;
    max-height: 288px;
    height: 100%;
    width: 100%;
    margin: 0 auto;
}

.title {
    text-align: center;
    font-size: 42px;
    color: #48587d;
    margin-bottom: 40px;
    margin-top: 107px;

    @media (max-width: 1400px) {
        font-size: 32px;
        margin-top: 1em;
        margin-bottom: 1em;
    }
}
