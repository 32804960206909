.conference {
    border-bottom: 1px solid #e9ecf4;
    display: flex;
    min-height: 80px;

    .info {
        flex: 1;
        padding: 8px 14px 10px;
        display: flex;
        flex-direction: column;

        .button {
            background-color: #2493fb;
            font-weight: 500;
            font-size: 14px;
            padding: 4px 8px;
            border-radius: 4px;
            color: #ffffff;
            height: auto;
            width: auto;
        }

        .header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 3px;

            & div {
                color: #48587d;
            }

            & div:first-child {
                font-weight: 500;
                font-size: 16px;
                display: flex;
                align-items: center;

                & img {
                    margin-left: 5px;
                    width: 16px;
                    height: 16px;
                }
            }

            & div:last-child {
                font-weight: normal;
                font-size: 16px;
                display: flex;

                & img {
                    margin-right: 2px;
                }
            }
        }

        .footer {
            display: flex;

            &__left {
                color: #777f95;
                font-size: 14px;
            }

            &__right {
                display: flex;
                align-items: flex-end;
                flex: 1;
                justify-content: flex-end;
            }

            &__invitedUsers {
                margin-top: 3px;
            }
        }

        &_started {
            background-color: #defac8;
            color: #35772a;
            border: none;

            .footer__left {
                color: #35772a;
            }

            .header div {
                color: #35772a;
            }
        }

        &:active {
            background-color: #e9ecf4;
        }
    }
}
