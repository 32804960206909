.container {
    @media (max-width: 1250px) {
        display: none;
    }
}

.quickMessageForm {
    position: relative;
    overflow: hidden;

    &_withPriorityFrame {
        .quickMessageForm__wrapper {
            border-bottom: 1px solid rgba(255, 255, 255, 1);

            input[type='text'] {
                color: #ffff;
                &::placeholder {
                    color: #ffff;
                }
            }
        }
        & button {
            background: url('../../../assets/PaperFlyWhite.svg') center center no-repeat;
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        align-self: end;
        margin: 7px 0;
        border-bottom: 2px solid rgba(255, 255, 255, 0.15);

        @media screen and (max-width: 1140px) {
            display: none;
        }

        > div:first-child {
            width: 100%;
        }

        input[type='text'] {
            background-color: rgba(0, 0, 0, 0);
            font-size: 14px;
            font-weight: 400;
            border: none;
            padding: 0 10px 0 5px;
            color: #fff;
            &::placeholder {
                color: #555555;
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                border: 1px solid transparent;
                -webkit-text-fill-color: #fff;
                -webkit-box-shadow: 0 0 0 1000px #121213 inset;
            }
        }
    }
}

.button {
    filter: grayscale(1);
    opacity: 0.8;
}

.msgLengthWarning {
    font-weight: 500;
    font-size: 10px;
    color: #adb9cf;
    position: absolute;
    top: -5px;
    left: 5px;
}

.msgLengthWarningIsBlinked {
    color: #d05353;
}
