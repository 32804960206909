.form {
    color: #48587d;

    &_mobile {
        margin-top: 45px;
        text-align: left;
    }

    & button {
        margin: 18px 0;
    }

    .block > div {
        margin-top: 6px;
        margin-bottom: 25px;
    }
}

a {
    text-decoration: none;
    color: #2493fb;
    font-size: 14px;
}

.label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #48587d;
    display: inline-block;
    &_mobile {
        margin-left: 15px;
        text-transform: uppercase;
        font-size: 12px;
    }
}

.agreement {
    font-size: 14px;
    &_mobile {
        margin: 0 15px;
    }
}

.persInfo,
.rules {
    color: #2493fb;
    cursor: pointer;
}

.inactiveButton {
    background-color: gray;
}

.fail {
    margin-top: -19px;
    margin-bottom: 19px;
}

.inputStyle {
    border-radius: 8px;
    height: 37px;
    font-size: 14px;
    padding: 10px 13px;

    &_mobile {
        height: 37px;
        & ~ div{
            margin-left: 15px;
        }
    }
}