.recordItem {
    color: #48587d;
    margin-bottom: 37px;
    display: flex;
    position: relative;

    &:hover {
        & > div {
            visibility: visible;
            color: #2493fb;
        }
    }

    &_noHover:hover {
        & > .info,
        & > .time {
            color: #48587d;
            cursor: auto;
        }
    }
}

.info {
    cursor: pointer;
    flex-basis: 550px;
    padding: 0 64px 0 0;
}

.name {
    font-weight: 500;
    font-size: 16px;
}

.id {
    font-weight: normal;
    font-size: 14px;
    margin-top: 10px;
}


.types {
    display: flex;
    margin-top: 40px;
    font-weight: 500;
    font-size: 16px;
    color: #48587d;

    & .type {
        flex-basis: 95px;
        cursor: pointer;
        flex-shrink: 0;

        &:last-child {
            margin-left: 64px;
        }

        &_active {
            color: #2493fb;
            padding-bottom: 6px;
            border-bottom: 2px solid #2493fb;
        }
    }
}

.time {
    font-weight: 500;
    font-size: 16px;
    flex-basis: 169px;
    cursor: pointer;
    flex-shrink: 0;
}