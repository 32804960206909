.avatar {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border: 2px solid transparent;
    overflow: hidden;
    user-select: none;
    box-sizing: border-box;
    position: relative;
    .image,
    .initials {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 50%;
    }
    .image {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .initials {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #fff;
        background-color: #85B66F;
        .guest {
            font-size: 14px;
            font-weight: 400;
            opacity: 0.5;
        }
    }
    &_active {
        box-shadow: 0 0 2px 2px #2493fb;
    }
}
