@import '../../../styles/animations.scss';

.spinner {
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: transparent;
    box-sizing: border-box;

    & > div {
        position: relative;
        width: 100%;
        height: 100%;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-left: 3px solid transparent;
        border-top: 3px solid #ffffff;
        border-radius: 50%;
        transform: rotate(45deg);
        box-sizing: border-box;
        @include spinAnimation;
    }

    &_login {
        display: flex;
        width: 50px;
        height: 50px;
        margin: 100px auto 30px;

        & > div {
            width: 100%;
            height: 100%;
            border-top: 3px solid #2493fb;
        }
    }

    &_medium {
        width: 20px;
        height: 20px;

        & > div {
            width: 90%;
            height: 90%;
            border-left: 3px solid #2493fb;
            border-top: 3px solid #2493fb;
        }
    }

    &_inProgress > div {
        width: 9px;
        height: 9px;
        border-right: 2px solid #777f95;
        border-top: 2px solid #777f95;
        border-left: 2px solid #777f95;
    }

    &_quarter > div {
        border-right: 2px solid #ffffff;
        border-top: 2px solid #ffffff;
        border-left: 2px solid #ffffff;
    }

    &_contentLoaderWhite {
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        margin: 0;
        position: absolute;
        z-index: 20;
        width: 45px;
        height: 45px;

        & > div {
            border-right: 4px solid #ffffff;
            border-top: 4px solid #ffffff;
            border-left: 4px solid #ffffff;
        }
    }

    &_contentLoaderBlue {
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        margin: 0;
        position: absolute;
        z-index: 20;
        width: 45px;
        height: 45px;

        & > div {
            border-right: 4px solid #2493fb;
            border-top: 4px solid #2493fb;
            border-left: 4px solid #2493fb;
        }
    }

    &_formMobileLoader {
        width: 15px;
        height: 15px;

        & > div {
            width: 90%;
            height: 90%;
            border-top: 3px solid transparent;
            border-left: 3px solid #2493fb;
        }
    }

    &_longBar {
        & > div {
            border-left-color: #ffffff;
            border-bottom-color: #ffffff;
        }
    }
}
