.participantsList {
    display: flex;
    flex-direction: column;

    &__item {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin: 4px 0;
    }
}

.guest {
    font-size: 14px;
    font-weight: 400;
    opacity: 0.5;
}
