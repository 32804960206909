.conferenceKebabWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 992px) {
        grid-area: kebabBtn;
    }

    @media (min-width: 765px) {
        align-items: flex-start;
    }
    > div {
        position: relative;
    }
}

.conferencekebabBtn {
    padding: 5px 10px;
    background: transparent;
    transition: background 0.3s;
    &:hover {
        background: #eaeaead9;
    }
    circle {
        fill: #aab1c6;
    }
}
