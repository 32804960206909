.root {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 110;

    & > div {
        z-index: 9;
        width: max-content;
        display: flex;
        align-items: center;
    }
}

.root_mobile {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 32px 14px 0 14px;
    box-sizing: border-box;

    & > div {
        z-index: 9;
    }
}
