.item {
    width: 180px;
    height: 180px;
    border-radius: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    vertical-align: top;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #fff;
    white-space: nowrap;
    padding-top: 29px;
    box-sizing: border-box;
    > .activeName {
        color: #2493fb;
    }
    &:hover {
        background-color: rgba(255, 255, 255, 0.04);
        .hoverMenu {
            display: flex;
        }
    }
    > .emotion {
        position: absolute;
        top: 50px;
        left: 40px;
        width: 100px;
        margin-top: 10px;
    }

    & > :last-child {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .nameBlock {
        display: flex;
        align-items: center;
        justify-content: center;
        .microMutedIcon {
            width: 14px;
            height: 14px;
            background: url("/assets/MicrophoneOffAvatar.svg") center center no-repeat;
            background-size: contain;
            margin-right: 4px;
        }
        .microDisabledIcon {
            background: url("/assets/MicrophoneOff.svg") center center no-repeat;
            background-size: contain;
        }
    }
}

.hoverMenu {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    padding: 14px 10px;
    z-index: 4;
}
