.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
    position: relative;
    color: #48587d;
    font-weight: 500;
    font-size: 20px;
}

.records {
    margin-top: 44px;
}


.conferenceGroup {
    color: #777f95;
    font-weight: 500;
    font-size: 14px;

    & > .date {
        margin-bottom: 20px;
    }
}

.dateFilter {
    margin: 21px 0;
}

.recordPlayer {
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    background-color: #191919;
    z-index: 99;
}