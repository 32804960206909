.form {
    color: #48587d;

    & button {
        margin: 18px 0;
    }

    & > .block {
        margin-bottom: 25px;
    }

    & input {
        margin-top: 6px;
    }

    & .rememberMe {
        font-size: 14px;

        & > label {
            &::before {
                border: 1px solid #cacfdb;
            }
        }
    }
}

a {
    text-decoration: none;
    color: #2493fb;
    font-size: 14px;
}

.label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #48587d;
    display: inline-block;
}

.passwordBlockHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.forgotPassword {
    border-bottom: 1px solid transparent;

    &:hover {
        border-bottom: 1px solid #1c87ea;
        color: #1c87ea;
    }
}

.agreement {
    font-size: 14px;
}

.persInfo,
.rules {
    color: #2493fb;
    cursor: pointer;
}

.inactiveButton {
    background-color: gray;
}

.fail {
    margin-top: -19px;
    margin-bottom: 19px;
    box-sizing: border-box;
}

.inputStyle {
    border-radius: 8px;
    height: 37px;
    font-size: 14px;
    padding: 10px 13px;
}

.popup {
    & > div:first-child {
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 24px;
    }
}

.agreement {
    flex-grow: 1;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    color: #48587d;
    max-width: 800px;
    max-height: 700px;

    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    }
    &::-webkit-scrollbar-thumb {
        background-color: #c4c4c4;
        outline: none;
        border-radius: 40px;
    }
}
