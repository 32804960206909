.name {
    font-size: 24px;
    margin-bottom: 31px;
    font-weight: 500;
    color: #48587d;
}

.annotation {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
    color: #777f95;
}

.buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 45px;
    flex-wrap: wrap;

    @media screen and (max-width: 1330px) {
        justify-content: center;

        & > button {
            flex-grow: 1;
            margin-bottom: 10px;
        }
    }
}

.button {
    width: 218px;
    margin-right: 20px;
}
