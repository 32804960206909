.page {
    display: flex;
    background-color: #121213;
    height: 100vh;
}

.wrapper {
    // max-width: 1440px;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.exitConfirmationDialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 19;
}
