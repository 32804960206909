.wrapper {
    position: relative;
    display: inline-block;
    user-select: none;
    &:hover {
        .tooltipLabel {
            visibility: visible;
            opacity: 1;
        }
    }
}

.device {
    background-color: rgba(255, 255, 255, 0);
    border-radius: 8px;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 9px;
    padding-bottom: 7px;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 100%;
    border: none;

    &_disabled {
        color: #A3A3A3;
    }

    &_disabledByAdmin {
        .deviceImgWrapper {
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                right: -52%;
                width: 10px;
                height: 13px;
                background: url('../../../assets/LockGray.svg') center center no-repeat;
            }
        }
    }

    &_open {
        & .arrowBlock {
            background-color: rgba(0, 0, 0, 0.1);
        }

        & > .arrow {
            position: absolute;
            top: 10px;
            left: 9px;
        }
        background-color: rgba(255, 255, 255, 0.1);
    }

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }

    &__statusText {
        margin-top: auto;
        font-weight: 500;

        &__mobile {
            font-size: 12px;
        }
    }

    &__arrowBlock {
        width: 24px;
        height: 24px;
        position: absolute;
        background-color: rgba(0, 0, 0, 0);
        border-radius: 8px;
        right: 5px;
        top: 5px;
        z-index: 5;

        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }

        & > .arrow {
            top: 10px;
            left: 9px;
        }
    }

    &_mobile {
        padding-top: 0;
        padding-bottom: 0;
    }

    .deviceImgWrapper {
        position: relative;
        display: flex;
    }
}

.menu {
    background-color: #242424;
    border-radius: 8px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    min-width: 218px;
    box-sizing: border-box;
    position: absolute;
    left: 73%;
    bottom: 105%;
    display: none;
    z-index: 6;

    &_open {
        display: block;
    }

    &__header {
        font-weight: 700;
        padding: 14px 14px 0 14px;
        margin-bottom: 5px;
    }

    &__item {
        position: relative;
        display: flex;
        max-width: 180px;
        padding: 10px 35px;
        cursor: pointer;

        &:hover {
            background: rgba(255, 255, 255, 0.05);
        }

        &_selected {
            &::before {
                content: url('../../../assets/Check.svg');
                position: absolute;
                left: 12px;
            }
        }

        &_surroundSound {
            border-top: solid 1px #666;
            form {
                flex-grow: 1;
                & > label {
                    display: block;
                    margin: -10px -35px;
                    padding: 10px 35px;
                    cursor: pointer;
                }                
            }
            .checkbox {
                position: absolute;
                left: 12px;
            }
        }
    }
}

.tooltipLabel {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    bottom: calc(100% + 5px);
    left: 50%;
    width: inherit;
    padding-bottom: 4px;
    transform: translateX(-50%);
    transition: opacity 0.2s;
    font-size: 12px;
    font-weight: 400;
    font-family: Roboto;
    color: #fff;
    border-radius: 4px;
    text-align: center;
    z-index: 13;
}
