.conferenceMembers {
    display: flex;
    padding: 0 15px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100%;
    box-sizing: border-box;
    width: 100%;
    /*
    & > div {
        width: 100%;
        display: flex;
        padding: 0;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        & > div {
            margin: 5px;
        }
    }*/
}
